import React, { useEffect } from 'react';
import { Close, RocketLaunch, AccessTime, Verified, ManageAccounts, InsertLink } from '@mui/icons-material';
import { IconButton, Divider, Dialog, Typography, Button, Card, CardContent, CardHeader, Avatar } from '@mui/material';
import Grid from '@mui/material/Grid2';
import imgTransparency from '../Images/Transparency.png'
import imgQuality from '../Images/quality.png'
import imgDecision from '../Images/decision-making.png'
import imgCustomise from '../Images/Customise.png'
import '../style.css'
import { UserForm } from './UserForm';
import { Helmet } from 'react-helmet';

export function Flow() {
    const [openForm, setOpenForm] = React.useState(false);
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [])

    return (
        <>
            <Helmet>
                <title>Flow - Streamlined Workflow Automation for Productivity</title>
                <meta name="description" content="Flow streamlines processes, boosts productivity, and optimizes task management. Achieve more with less effort using intuitive automation tools designed to enhance your workflow." />
                <meta name="keywords" content="Workflow Automation Solution, Advanced Task Management Tools, Business Process Automation, Productivity Optimization, Automated Workflow Software, Workflow Process Efficiency, Task Scheduling and Management, Enterprise Automation Tools, Business Workflow Optimization, Streamlined Process Automation." />
                <link rel="canonical" href="https://aarohasolutions.com/Flow"></link>
                <meta property="og:url" content="https://aarohasolutions.com/Flow" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Flow - Streamlined Workflow Automation for Productivity" />
                <meta property="og:site_name" content="Aaroha Solutions Flow" />
                <meta property="og:description" content="Flow’s workflow automation solution streamlines your business processes, boosts productivity, and simplifies task management with intuitive automation tools." />
                <meta property="og:image" content="https://aarohasolutions.com/static/media/flow-logo.79bd466176ce841773f7.png" />
                <meta property="og:image:width" content="2400" />
                <meta property="og:image:height" content="720" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Flow - Streamlined Workflow Automation for Enhanced Productivity" />
                <meta name="twitter:description" content="Streamline business processes and boost productivity with Flow, the powerful workflow automation solution. Achieve more with less effort and maximize efficiency." />
                <meta name="twitter:image" content="https://aarohasolutions.com/static/media/flow-logo.79bd466176ce841773f7.png" />
            </Helmet>

            <div className='flowwithoutbackgroudimage' style={{ textAlign: 'center', backgroundColor: '#f7f8fd', paddingTop: 50, paddingBottom: 50, paddingLeft: 20, minHeight: 300 }}>
                <h1 style={{ marginBlockEnd: 0, marginBlockStart: 0, fontSize: 55, fontWeight: 700, color: '#3B83BD', paddingTop: 100, textAlign: 'left' }}>Optimize Your Business process</h1>
                <h2 style={{ marginBlockEnd: 0, marginBlockStart: 0, fontSize: 25, fontWeight: 500, color: '#3B83BD', paddingTop: 20, textAlign: 'left' }}>Take control of your operations with our comprehensive workflow management solution.</h2>
                <a href="#ready"><Button variant='contained' disableElevation style={{ textTransform: 'none', fontSize: 25, fontWeight: 500, marginTop: 20 }}>Get Started</Button></a>
            </div>

            <h1 style={{ marginBlockEnd: 0, marginBlockStart: 0, fontSize: 35, fontWeight: 700, color: '#3B83BD', paddingTop: 40, paddingBottom: 40, textAlign: 'center' }}>Why Choose Flow?</h1>
            <Grid container style={{ justifyContent: 'center', textAlign: 'center', paddingLeft: 2, paddingRight: 2, paddingBottom: 55 }} spacing={{ xs: 1, md: 1 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                <Grid size={{ xs: 3.8, sm: 3, md: 3 }}>
                    <Card variant='outlined' style={{ border: 'none', borderRadius: 12, textAlign: 'left', backgroundColor: '#ecf8f2' }} sx={{ ':hover': { boxShadow: 20, }, }}>
                        <CardContent style={{ padding: 10 }}><img src={imgTransparency} alt={'Enhanced Transparency'} style={{ width: 50, height: 50 }} /></CardContent>
                        <CardContent style={{ minHeight: 150 }}>
                            <Typography style={{ textAlign: 'left', fontSize: 25, fontWeight: 600, color: '#3B83BD', }}>Enhanced Transparency</Typography>
                            <h3 style={{ marginBlockEnd: 0, marginBlockStart: 0, fontWeight: 400, fontSize: 17 }}>Gain complete visibility over your operations with real-time tracking and reporting.</h3>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid size={{ xs: 3.8, sm: 3, md: 3 }}>
                    <Card variant='outlined' style={{ border: 'none', backgroundColor: '#fdf7e6', borderRadius: 10, textAlign: 'left', marginLeft: 3 }} sx={{ ':hover': { boxShadow: 20, }, }}>
                        <CardContent style={{ padding: 10 }}><img src={imgQuality} alt={'Improved Quality'} style={{ width: 50, height: 50 }} /></CardContent>
                        <CardContent style={{ minHeight: 150 }}>
                            <Typography style={{ textAlign: 'left', fontSize: 25, fontWeight: 600, color: '#3B83BD', }}>Improved Quality</Typography>
                            <h3 style={{ marginBlockEnd: 0, marginBlockStart: 0, fontWeight: 400, fontSize: 17 }}> Ensure top-quality results with integrated quality control features that reduce errors by 25%.</h3>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid size={{ xs: 3.8, sm: 3, md: 3 }}>
                    <Card variant='outlined' style={{ border: 'none', backgroundColor: '#fceeef', borderRadius: 10, textAlign: 'left', marginLeft: 3 }} sx={{ ':hover': { boxShadow: 20, }, }}>
                        <CardContent style={{ padding: 10 }}><img src={imgDecision} alt={'Quick Decision-Making'} style={{ width: 50, height: 50 }} /></CardContent>
                        <CardContent style={{ minHeight: 150 }}>
                            <Typography style={{ textAlign: 'left', fontSize: 25, fontWeight: 600, color: '#3B83BD', }}>Quick Decision-Making</Typography>
                            <h3 style={{ marginBlockEnd: 0, marginBlockStart: 0, fontWeight: 400, fontSize: 17 }}>Make informed decisions faster with our advanced analytics and reporting tools.</h3>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid size={{ xs: 3.8, sm: 3, md: 3 }}>
                    <Card variant='outlined' style={{ border: 'none', backgroundColor: '#f1f3f5', borderRadius: 10, textAlign: 'left', marginLeft: 3 }} sx={{ ':hover': { boxShadow: 20, }, }}>
                        <CardContent style={{ padding: 10 }}><img src={imgCustomise} alt={'Customizable Workflows'} style={{ width: 50, height: 50 }} /></CardContent>
                        <CardContent style={{ minHeight: 150 }}>
                            <Typography style={{ textAlign: 'left', fontSize: 25, fontWeight: 600, color: '#3B83BD', }}>Customizable Workflows</Typography>
                            <h3 style={{ marginBlockEnd: 0, marginBlockStart: 0, fontWeight: 400, fontSize: 17 }}>Adapt to changing business needs with highly customizable workflows.</h3>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            <div style={{ backgroundColor: '#f7f8fd', paddingTop: 10, paddingBottom: 50 }} id="solutions">
                <h1 style={{ marginBlockEnd: 0, marginBlockStart: 0, fontSize: 30, fontWeight: 700, color: '#3B83BD', paddingTop: 50, paddingBottom: 50, textAlign: 'center' }}>Flow Capabilities: The Engine Behind Your Success</h1>
                <Grid container style={{ justifyContent: 'center', paddingLeft: 3 }} spacing={{ xs: 1, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                    <Grid size={{ xs: 3.8, sm: 4, md: 4 }}>
                        <Card variant='outlined' style={{ borderColor: '#f7f8fd', backgroundColor: '#fceeef', borderRadius: 10, position: 'relative', minHeight: 230 }} sx={{ ':hover': { boxShadow: 20, }, }}>
                            <CardHeader avatar={<Avatar sx={{ backgroundColor: '#3B83BD', width: 45, height: 45 }}><RocketLaunch sx={{ color: 'white', fontSize: 30 }} /></Avatar>} title={<Typography style={{ fontSize: 25, fontWeight: 600 }}>Workflow Automation</Typography>} />
                            <CardContent>
                                <h3 style={{ marginBlockEnd: 0, marginBlockStart: 0, fontWeight: 400, fontSize: 18, color: 'gray' }}>Automate task generation and distribution, manage queries seamlessly, and optimize resource use.</h3>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid size={{ xs: 3.8, sm: 4, md: 4 }}>
                        <Card variant='outlined' style={{ borderColor: '#f7f8fd', backgroundColor: '#f3f9ff', borderRadius: 10, position: 'relative', minHeight: 230 }} sx={{ ':hover': { boxShadow: 20, }, }}>
                            <CardHeader avatar={<Avatar sx={{ backgroundColor: '#3B83BD', width: 45, height: 45 }}><AccessTime sx={{ color: 'white', fontSize: 30 }} /></Avatar>} title={<Typography style={{ fontSize: 25, fontWeight: 600 }}>Time Tracking & Performance</Typography>} />
                            <CardContent>
                                <h3 style={{ marginBlockEnd: 0, marginBlockStart: 0, fontWeight: 400, fontSize: 18, color: 'gray' }}>Monitor productive and non-productive time, gain real-time insights, and improve SLA adherence.</h3>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid size={{ xs: 3.8, sm: 4, md: 4 }}>
                        <Card variant='outlined' style={{ borderColor: '#f7f8fd', backgroundColor: '#fef6ec', borderRadius: 10, position: 'relative', minHeight: 230 }} sx={{ ':hover': { boxShadow: 20, }, }}>
                            <CardHeader avatar={<Avatar sx={{ backgroundColor: '#3B83BD', width: 45, height: 45 }}><Verified sx={{ color: 'white', fontSize: 30 }} /></Avatar>} title={<Typography style={{ fontSize: 25, fontWeight: 600 }}>Quality Assurance</Typography>} />
                            <CardContent>
                                <h3 style={{ marginBlockEnd: 0, marginBlockStart: 0, fontWeight: 400, fontSize: 18, color: 'gray' }}>Conduct real-time error checks, use visual indicators, and reduce quality-related rework.</h3>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid size={{ xs: 3.8, sm: 4, md: 4 }}>
                        <Card variant='outlined' style={{ borderColor: '#f7f8fd', backgroundColor: '#fdf7e6', borderRadius: 10, position: 'relative', minHeight: 230 }} sx={{ ':hover': { boxShadow: 20, }, }}>
                            <CardHeader avatar={<Avatar sx={{ backgroundColor: '#3B83BD', width: 45, height: 45 }}><ManageAccounts sx={{ color: 'white', fontSize: 30 }} /></Avatar>} title={<Typography style={{ fontSize: 25, fontWeight: 600 }}>Resource Management</Typography>} />
                            <CardContent>
                                <h3 style={{ marginBlockEnd: 0, marginBlockStart: 0, fontWeight: 400, fontSize: 18, color: 'gray' }}>Plan resources effectively, anticipate future needs, and reduce staffing costs with Flow’s predictive analytics.</h3>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid size={{ xs: 3.8, sm: 4, md: 4 }}>
                        <Card variant='outlined' style={{ borderColor: '#f7f8fd', backgroundColor: '#ecf8f2', borderRadius: 10, position: 'relative', minHeight: 230 }} sx={{ ':hover': { boxShadow: 20, }, }}>
                            <CardHeader avatar={<Avatar sx={{ backgroundColor: '#3B83BD', width: 45, height: 45 }}><InsertLink sx={{ color: 'white', fontSize: 30 }} /></Avatar>} title={<Typography style={{ fontSize: 25, fontWeight: 600 }}>Integration & Configuration</Typography>} />
                            <CardContent>
                                <h3 style={{ marginBlockEnd: 0, marginBlockStart: 0, fontWeight: 400, fontSize: 18, color: 'gray' }}>Tailor workflows, SLAs, and user roles, and integrate with existing systems for a seamless workflow.</h3>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </div>
            <h1 style={{ marginBlockEnd: 0, marginBlockStart: 0, color: '#3B83BD', fontSize: 35, fontWeight: 600, paddingTop: 50, textAlign: 'center', backgroundColor: '#f7f8fd' }}>Ready to Transform Your Workflow?</h1>
            <div id="ready" style={{ justifyContent: 'center', textAlign: 'center', backgroundColor: '#f7f8fd' }}>
                <Button variant='contained' disableElevation style={{ textTransform: 'none', fontSize: 25, fontWeight: 500, marginTop: 10, marginBottom: 30, textAlign: 'center', justifySelf: 'center' }} onClick={(x) => setOpenForm(true)}>Get Started Today</Button>
            </div>

            <Dialog open={openForm} fullWidth={true} style={{ padding: 0, }} maxWidth={'md'} disableEscapeKeyDown>
                <Card>
                    <CardHeader style={{ padding: 6, paddingLeft: 10, paddingRight: 10, backgroundColor: '#e6e6e6' }} title={'Get document'} titleTypographyProps={{ variant: 'body1' }} action={<IconButton aria-label="Close" onClick={() => setOpenForm(false)}><Close color='error' /></IconButton>} />
                    <Divider />
                    <div style={{ backgroundColor: '#F7F8FD', textAlign: 'center', height: 50 }}>
                        <Typography style={{ color: '#2e2e2e', fontSize: 20, fontWeight: 500, paddingTop: 10 }}>Get the <strong>Flow</strong> document on your email id.</Typography>
                    </div>

                    <UserForm page='Flow' action='Send Email' />
                </Card>
            </Dialog>
        </>
    );

}
