import React from 'react';
import { Close, ArrowOutward } from '@mui/icons-material';
import { Divider, Dialog, Alert, IconButton, CardContent, Card, Typography, Button, AlertTitle, CardHeader, Avatar } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Link } from 'react-router-dom';
import '../style.css'
import { UserForm } from './UserForm';
import imgTrendup from '../Images/TrendUp.png'
import imgOptimization from '../Images/Optimization.png'
import imgImprovement from '../Images/Continuous-improvement.png'
import imgFlowlogo from '../Images/flow-logo.png'
import { Helmet } from 'react-helmet';

export function Home() {
    const [openForm, setOpenForm] = React.useState(false);
    return (
        <>
            <Helmet>
                <title>Aaroha Solutions - Innovative Software for Business Growth</title>
                <meta name="description" content="Aaroha Solutions offers a suite of innovative software solutions, including Clarity, Flow, Momentum, and Precisely, designed to drive business growth, streamline operations, and enhance decision-making." />
                <meta name="keywords" content="Workflow Automation Software, Performance Management Tools, Quality Assurance Automation, Business Process Optimization, Productivity Enhancement Software, Operational Excellence Tools, Task Management Solutions, Intelligent Quality Management, Real-Time Performance Tracking, Workforce Optimization Software." />
                <link rel="canonical" href="https://aarohasolutions.com"></link>
                <meta property="og:url" content="https://aarohasolutions.com" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Aaroha Solutions - Innovative Software Solutions for Business Growth" />
                <meta property="og:site_name" content="Aaroha Solutions" />
                <meta property="og:description" content="Explore Aaroha Solutions' powerful software suite — Clarity, Flow, Momentum, and Precisely—designed to enhance decision-making, streamline automation, and ensure quality management for business growth." />
                <meta property="og:image" content="https://aarohasolutions.com/static/media/aaroha-logo.77e8bf558bacdb7f4c7e.png" />
                <meta property="og:image:width" content="2400" />
                <meta property="og:image:height" content="720" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Aaroha Solutions - Innovative Software Solutions for Business Growth" />
                <meta name="twitter:description" content="Explore Aaroha Solutions' software suite, including Clarity, Flow, Momentum, and Precisely, designed to enhance business growth and streamline operations." />
                <meta name="twitter:image" content="https://aarohasolutions.com/static/media/aaroha-logo.77e8bf558bacdb7f4c7e.png" />
            </Helmet>
            <div className='backgroudimage' style={{ backgroundColor: '#66c1e5', textAlign: 'center', paddingBottom: 20 }}>
                <h1 style={{ marginBlockEnd: 0, marginBlockStart: 0, fontSize: 55, fontWeight: 700, color: 'white', paddingTop: 100 }}>Innovative Software Solutions for Your Business</h1>
                <h2 style={{ marginBlockEnd: 0, marginBlockStart: 0, fontSize: 25, fontWeight: 500, color: 'white', paddingTop: 20 }}>Empowering businesses with cutting-edge technology solutions</h2>
                <Button variant='contained' disableElevation style={{ backgroundColor: '#EA5141', textTransform: 'none', fontSize: 25, fontWeight: 600, marginTop: 10, borderRadius: 30 }} onClick={(x) => setOpenForm(true)}>Request Demo</Button>
            </div>
            <div style={{ backgroundColor: '#f7f8fd', paddingTop: 10, paddingBottom: 30 }} id="solutions">
                <h1 style={{ marginBlockEnd: 0, marginBlockStart: 0, fontSize: 35, fontWeight: 650, color: '#3B83BD', paddingTop: 50, paddingBottom: 50, textAlign: 'center' }}>Our Solutions</h1>
                <Grid container style={{ justifyContent: 'center', paddingLeft: 3 }} spacing={{ xs: 1, md: 1 }} columns={{ xs: 3, sm: 8, md: 12 }}>
                    <Grid size={{ xs: 2.7, sm: 3, md: 3 }}>
                        <Card variant='outlined' style={{ borderRadius: 10, position: 'relative' }} sx={{ ':hover': { boxShadow: 20, }, }}>
                            <CardHeader style={{ minHeight: 115 }} title={<Typography style={{ fontSize: 30, fontWeight: 700, color: '#3B83BD' }}>Flow</Typography>} subheader={<h3 style={{ marginBlockEnd: 0, marginBlockStart: 0, fontWeight: 100, color: 'gray', padding: 5, textAlign: 'left', fontSize: 18 }}>Streamline your Workflows with precision and efficiency.</h3>} action={<><Avatar sx={{ backgroundColor: '#3B83BD', cursor: 'pointer', width: 45, height: 45, }}><Link to="/Flow" style={{ textDecoration: 'none' }}><ArrowOutward sx={{ color: 'white', fontSize: 30, '&:hover': { transform: 'rotate(45deg);', transition: 'all .5s' } }} /></Link></Avatar></>} />
                            <Divider />
                            <CardContent className='flowbackgroudimage' style={{ height: 210, padding: 15 }} />                            
                        </Card>
                    </Grid>
                    <Grid size={{ xs: 2.7, sm: 3, md: 3 }}>
                        <Card variant='outlined' style={{ borderRadius: 10, position: 'relative' }} sx={{ ':hover': { boxShadow: 20, }, }}>
                            <CardHeader style={{ minHeight: 115 }} title={<Typography style={{ fontSize: 30, fontWeight: 700, color: '#3B83BD' }}>Momentum</Typography>} subheader={<h3 style={{ marginBlockEnd: 0, marginBlockStart: 0, fontWeight: 100, color: 'gray', padding: 5, textAlign: 'left', fontSize: 18 }}>Empower performance with seamless management.</h3>} action={<><Avatar sx={{ backgroundColor: '#3B83BD', cursor: 'pointer', width: 45, height: 45 }}><Link to="/Momentum" style={{ textDecoration: 'none' }}><ArrowOutward sx={{ color: 'white', fontSize: 30, '&:hover': { transform: 'rotate(45deg)', transition: 'all .5s' } }} /></Link></Avatar></>} />
                            <Divider />
                            <CardContent className='momentumbackgroudimage' style={{ height: 210, padding: 15 }} />
                        </Card>
                    </Grid>
                    <Grid size={{ xs: 2.7, sm: 3, md: 3 }}>
                        <Card variant='outlined' style={{ borderRadius: 10, position: 'relative' }} sx={{ ':hover': { boxShadow: 20, }, }}>
                            <CardHeader style={{ minHeight: 115 }} title={<Typography style={{ fontSize: 30, fontWeight: 700, color: '#3B83BD' }}>Precisely</Typography>} subheader={<h3 style={{ marginBlockEnd: 0, marginBlockStart: 0, fontWeight: 100, color: 'gray', padding: 5, textAlign: 'left', fontSize: 18 }}>Ensure quality with highest level of precision.</h3>} action={<><Avatar sx={{ backgroundColor: '#3B83BD', cursor: 'pointer', width: 45, height: 45 }}><Link to="/Precisely" style={{ textDecoration: 'none' }}><ArrowOutward sx={{ color: 'white', fontSize: 30, '&:hover': { transform: 'rotate(45deg)', transition: 'all .5s' } }} /></Link></Avatar></>} />
                            <Divider />
                            <CardContent className='preciselybackgroudimage' style={{ height: 210, padding: 15 }}></CardContent>
                        </Card>
                    </Grid>
                    <Grid size={{ xs: 2.7, sm: 3, md: 3 }}>
                        <Card variant='outlined' style={{ borderRadius: 10, position: 'relative' }} sx={{ ':hover': { boxShadow: 20, }, }}>
                            <CardHeader style={{ minHeight: 115 }} title={<Typography style={{ fontSize: 30, fontWeight: 700, color: '#3B83BD' }}>Clarity</Typography>} subheader={<h3 style={{ marginBlockEnd: 0, marginBlockStart: 0, fontWeight: 100, color: 'gray', padding: 5, textAlign: 'left', fontSize: 18 }}>Real-time employee monitoring for productivity.</h3>} action={<><Avatar sx={{ backgroundColor: '#3B83BD', cursor: 'pointer', width: 45, height: 45 }}><Link to="/Clarity" style={{ textDecoration: 'none' }}><ArrowOutward sx={{ color: 'white', fontSize: 30, '&:hover': { transform: 'rotate(45deg)', transition: 'all .5s' } }} /></Link></Avatar></>} />
                            <Divider />
                            <CardContent className='claritybackgroudimage' style={{ height: 210, padding: 15 }}></CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </div>


            <Typography style={{ backgroundColor: '#f7f8fd', fontSize: 40, fontWeight: 600, color: '#3B83BD', textAlign: 'center', paddingBottom: 50 }}>Our 3-Step Approach to Ensure Your Success</Typography >
            <div style={{ paddingLeft: '5%', paddingRight: '5%', backgroundColor: '#f7f8fd', paddingBottom: 20 }}>
                <Grid container style={{ backgroundColor: '#f7f8fd', justifyContent: 'center', paddingLeft: 3 }} spacing={{ xs: 1, md: 1 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                    <Grid size={{ xs: 4, sm: 4, md: 4 }}>
                        <Card variant='outlined' style={{ backgroundColor: '#FDF7E6', border: 'none', borderRadius: 10, position: 'relative' }} sx={{ ':hover': { boxShadow: 20, }, }}>
                            <CardHeader style={{ padding: 10 }} avatar={<><img src={imgTrendup} alt={'Monitoring & Quick Wins'} style={{ width: 50, height: 50 }} /></>} />
                            <CardHeader style={{ padding: 10, minHeight: 90 }} title={<Typography style={{ fontSize: 20, fontWeight: 550, color: '#3B83BD' }}>Step 1: Early Monitoring & Quick Wins</Typography>} subheader={<Typography style={{ color: 'gray', textAlign: 'left', fontSize: 18 }}>(Month 1–2)</Typography>} />
                            <CardContent style={{ padding: 8 }}>
                                <Typography sx={{ fontSize: 16, color: 'text.secondary', paddingBottom: '10px' }}>We focus on delivering immediate value through early monitoring and quick process improvements.</Typography>
                                <Typography sx={{ fontSize: 16, color: 'text.secondary', paddingBottom: '10px' }}><strong>Process Validation:</strong>Track impact on critical processes, ensuring early adoption.</Typography>
                                <Typography sx={{ fontSize: 16, color: 'text.secondary' }}><strong>Quick Wins:</strong>Leverage early successes to build momentum and engagement.</Typography><br />
                                <Typography sx={{ fontSize: 16, color: 'text.secondary' }}><strong>Real-Time Adjustments:</strong>Make refinements based on feedback and data.</Typography><br />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid size={{ xs: 4, sm: 4, md: 4 }}>
                        <Card variant='outlined' style={{ backgroundColor: '#FCEEEF', border: 'none', borderRadius: 10, position: 'relative' }} sx={{ ':hover': { boxShadow: 20, }, }}>
                            <CardHeader style={{ padding: 10 }} avatar={<><img src={imgOptimization} alt={'Optimization & Expansion'} style={{ width: 50, height: 50 }} /></>} />
                            <CardHeader style={{ padding: 10, minHeight: 90 }} title={<Typography style={{ fontSize: 20, fontWeight: 550, color: '#3B83BD' }}>Step 2: Optimization & Expansion</Typography>} subheader={<Typography style={{ color: 'gray', textAlign: 'left', fontSize: 18 }}>(Month 3–6)</Typography>} />
                            <CardContent style={{ padding: 8 }}>
                                <Typography sx={{ fontSize: 16, color: 'text.secondary', paddingBottom: '10px' }}>We scale the impact of the solution across teams, ensuring organization-wide improvements.</Typography>
                                <Typography sx={{ fontSize: 16, color: 'text.secondary', paddingBottom: '10px' }}><strong>Process Optimization:</strong>Refine processes using data insights for maximum impact.</Typography>
                                <Typography sx={{ fontSize: 16, color: 'text.secondary' }}><strong>Scaling Across Departments:</strong>Standardize improvements across teams and departments.</Typography><br />
                                <Typography sx={{ fontSize: 16, color: 'text.secondary' }}><strong>Advanced Insights:</strong>Use analytics for continuous refinement and optimization.</Typography><br />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid size={{ xs: 4, sm: 4, md: 4 }}>
                        <Card variant='outlined' style={{ backgroundColor: '#F1F3F5', border: 'none', borderRadius: 10, position: 'relative' }} sx={{ ':hover': { boxShadow: 20, }, }}>
                            <CardHeader style={{ padding: 10 }} avatar={<><img src={imgImprovement} alt={'Continuous Improvement & Long-Term Partnership'} style={{ width: 50, height: 50 }} /></>} />
                            <CardHeader style={{ padding: 10, minHeight: 90 }} title={<Typography style={{ fontSize: 20, fontWeight: 550, color: '#3B83BD' }}>Step 3: Continuous Improvement & Long-Term Partnership</Typography>} subheader={<Typography style={{ color: 'gray', textAlign: 'left', fontSize: 18 }}>(Month 6 and Beyond)</Typography>} />
                            <CardContent style={{ padding: 8 }}>
                                <Typography sx={{ fontSize: 16, color: 'text.secondary', paddingBottom: '10px' }}>We adapt the solution as your business evolves, ensuring long-term success and sustained improvements.</Typography>
                                <Typography sx={{ fontSize: 16, color: 'text.secondary', paddingBottom: '10px' }}><strong>Ongoing Process Audits:</strong>Regular audits to ensure continuous alignment with business goals.</Typography>
                                <Typography sx={{ fontSize: 16, color: 'text.secondary' }}><strong>Adaptation & Innovation:</strong>Refine processes as your organization grows and faces new challenges.</Typography><br />
                                <Typography sx={{ fontSize: 16, color: 'text.secondary' }}><strong>Long-Term Support:</strong>Continuous training, updates, and support to maximize potential.</Typography><br />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </div>

            <h1 style={{ marginBlockEnd: 0, marginBlockStart: 0, backgroundColor: '#f7f8fd', fontSize: 40, fontWeight: 600, color: '#3B83BD', textAlign: 'center', paddingTop: 50, paddingBottom: 50 }}>About aaroha solutions</h1 >
            <div style={{ backgroundColor: '#f7f8fd', paddingLeft: '20%' }}>
                <Alert severity="success" style={{ borderRadius: 0, background: 'none', fontSize: 18 }}>
                    <AlertTitle>At aaroha solutions, we are dedicated to empowering businesses through:</AlertTitle>
                    <strong>Cutting-edge Software Products</strong> Designed to optimize operations, elevate performance, and guarantee quality.
                </Alert>
                <Alert severity="success" style={{ borderRadius: 0, background: 'none', fontSize: 18 }}>
                    <AlertTitle>We focus on:</AlertTitle>
                    <strong>Increasing Efficiency</strong> Minimizing waste, maximizing resource utilization, and achieving superior customer satisfaction.
                </Alert>
                <Alert severity="success" style={{ borderRadius: 0, background: 'none', fontSize: 18 }}>
                    <AlertTitle>Our commitment:</AlertTitle>
                    <strong>Driving Tangible Improvements</strong> Ensuring that clients derive maximum benefit within the constraints of their resources.
                </Alert>
                <Alert severity="success" style={{ borderRadius: 0, background: 'none', fontSize: 18 }}>
                    At aaroha solutions, we don’t just provide software—we deliver results that matter.
                </Alert>
            </div>

            <Typography style={{ backgroundColor: '#f7f8fd', fontSize: 40, fontWeight: 600, color: '#3B83BD', textAlign: 'center', paddingTop: 50, paddingBottom: 30, }}>Contact Us</Typography>
            <Typography style={{ backgroundColor: '#f7f8fd', fontSize: 18, textAlign: 'center', paddingBottom: 50, }}>Interested in learning more? Get in touch with us to find out how we can help your business grow.</Typography>
            <div style={{ justifyContent: 'center', textAlign: 'center', backgroundColor: '#f7f8fd' }}>
                <Link to="/Contactus" style={{ textDecoration: 'none' }}><Button variant='contained' disableElevation style={{ marginBottom: 20, textTransform: 'none', fontSize: 25, fontWeight: 600, textAlign: 'center' }}>Contact Us</Button></Link>
            </div>


            <Dialog open={openForm} fullWidth={true} style={{ padding: 0, }} maxWidth={'md'} disableEscapeKeyDown>
                <Card>
                    <CardHeader style={{ padding: 6, paddingLeft: 10, paddingRight: 10, backgroundColor: '#e6e6e6' }} title={'Request a Demo'} titleTypographyProps={{ variant: 'body1' }} action={<IconButton aria-label="Close" onClick={() => setOpenForm(false)}><Close color='error' /></IconButton>} />
                    <Divider />
                    <div style={{ backgroundColor: '#F7F8FD', textAlign: 'center', height: 50 }}>
                        <Typography style={{ color: '#2e2e2e', fontSize: 20, fontWeight: 500, paddingTop: 10 }}>Request personalized Demo</Typography>
                    </div>

                    <UserForm page='Demo request' action='Submit' />
                </Card>
            </Dialog>

        </>
    );

}
