import React, { useState, useEffect } from 'react';
import { Close, AccessTime, Dashboard, Settings, SentimentSatisfied, PermPhoneMsg, Scale } from '@mui/icons-material';
import { IconButton, Divider, Dialog, Typography, Button, Card, CardContent, CardHeader, Avatar, } from '@mui/material';
import Grid from '@mui/material/Grid2';
import imgAccuracy from '../Images/Accuracy.png'
import imgProductivity from '../Images/Productivity.png'
import imgEngagement from '../Images/Engagement.png'
import imgManagement from '../Images/Management.png'
import imgConfiguration from '../Images/Configuration.png'
import { Link } from 'react-router-dom';
import '../style.css'
import { UserForm } from './UserForm';
import { Helmet } from 'react-helmet';

export function Momentum() {
    const [openForm, setOpenForm] = React.useState(false);
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [])

    return (
        <>

            <Helmet>
                <title>Elevate Performance with Real-Time Insights & Precise Tracking</title>
                <meta name="description" content="Elevate performance management with Momentum. Optimize your workforce with real-time insights and accurate performance tracking. Achieve better results with precise performance evaluation and workforce optimization." />
                <meta name="keywords" content="Performance Management, Real-Time Insights, Workforce Optimization, Accurate Performance Tracking, Employee Performance Tools, Performance Evaluation, Workforce Analytics, Performance Improvement, Productivity Enhancement, Performance Monitoring" />
                <link rel="canonical" href="https://aarohasolutions.com/Momentum"></link>
                <meta property="og:url" content="https://aarohasolutions.com/Momentum" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Elevate Performance with Real-Time Insights & Precise Tracking" />
                <meta property="og:site_name" content="Aaroha Solutions Flow" />
                <meta property="og:description" content="Momentum empowers your business with real-time insights and precise performance tracking to optimize workforce management and drive better results." />
                <meta property="og:image" content="https://aarohasolutions.com/static/media/momentum-logo.473279cfe5a2d88d5992.png" />
                <meta property="og:image:width" content="2400" />
                <meta property="og:image:height" content="720" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Elevate Performance with Real-Time Insights & Precise Tracking" />
                <meta name="twitter:description" content="Optimize workforce management with Momentum. Gain real-time insights and track performance accurately to drive success." />
                <meta name="twitter:image" content="https://aarohasolutions.com/static/media/momentum-logo.473279cfe5a2d88d5992.png" />
            </Helmet>
            <div className='momentumwithoutbackgroudimage' style={{ textAlign: 'center', backgroundColor: '#f7f8fd', paddingTop: 20, paddingBottom: 50, paddingLeft: 20 }}>
                <h1 style={{ marginBlockEnd: 0, marginBlockStart: 0, fontSize: 55, fontWeight: 700, color: '#3B83BD', paddingTop: 100, textAlign: 'left' }}>Elevate Your Performance Management</h1>
                <h2 style={{ marginBlockEnd: 0, marginBlockStart: 0, fontSize: 25, fontWeight: 500, color: '#3B83BD', paddingTop: 20, textAlign: 'left' }}>Optimize your workforce with real-time monitoring and accurate performance tracking.</h2>
                <a href="#ready"><Button variant='contained' disableElevation style={{ textTransform: 'none', fontSize: 25, fontWeight: 500, marginTop: 20 }}>Get Started</Button></a>
            </div>

            <Typography style={{ fontSize: 35, fontWeight: 600, color: '#3B83BD', paddingTop: 40, paddingBottom: 40, textAlign: 'center' }}>Why Momentum?</Typography>
            <Grid container style={{ justifyContent: 'center', textAlign: 'center', paddingLeft: 2, paddingRight: 2, paddingBottom: 55 }} spacing={{ xs: 1, md: 1 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                <Grid size={{ xs: 3.8, sm: 4, md: 4 }}>
                    <Card variant='outlined' style={{ backgroundColor: '#f3f9ff', border: 'none', borderRadius: 10, textAlign: 'left' }} sx={{ ':hover': { boxShadow: 20, }, }}>
                        <CardContent style={{ padding: 10 }}><img src={imgAccuracy} alt={'Unmatched Accuracy'} style={{ width: 50 }} /></CardContent>
                        <CardContent style={{ minHeight: 150 }}>
                            <Typography style={{ textAlign: 'left', fontSize: 25, fontWeight: 600, color: '#3B83BD', }}>Unmatched Accuracy</Typography>
                            <h3 style={{ marginBlockEnd: 0, marginBlockStart: 0, fontWeight: 400, fontSize: 17 }}>Momentum tracks every minute of work with precision, improving data accuracy by 25%.</h3>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid size={{ xs: 3.8, sm: 4, md: 4 }}>
                    <Card variant='outlined' style={{ backgroundColor: '#fceeef', border: 'none', borderRadius: 10, textAlign: 'left', marginLeft: 3 }} sx={{ ':hover': { boxShadow: 20, }, }}>
                        <CardContent style={{ padding: 10 }}><img src={imgProductivity} alt={'Boosted Productivity'} style={{ width: 50 }} /></CardContent>
                        <CardContent style={{ minHeight: 150 }}>
                            <Typography style={{ textAlign: 'left', fontSize: 25, fontWeight: 600, color: '#3B83BD', }}>Boosted Productivity</Typography>
                            <h3 style={{ marginBlockEnd: 0, marginBlockStart: 0, fontWeight: 400, fontSize: 17 }}>Real-time performance dashboards reduce idle time by 20% and increase productivity by 15%.</h3>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid size={{ xs: 3.8, sm: 4, md: 4 }}>
                    <Card variant='outlined' style={{ backgroundColor: '#fef6ec', border: 'none', borderRadius: 10, textAlign: 'left', marginLeft: 3 }} sx={{ ':hover': { boxShadow: 20, }, }}>
                        <CardContent style={{ padding: 10 }}><img src={imgEngagement} alt={'Enhanced Engagement'} style={{ width: 50 }} /></CardContent>
                        <CardContent style={{ minHeight: 150 }}>
                            <Typography style={{ textAlign: 'left', fontSize: 25, fontWeight: 600, color: '#3B83BD', }}>Enhanced Engagement</Typography>
                            <h3 style={{ marginBlockEnd: 0, marginBlockStart: 0, fontWeight: 400, fontSize: 17 }}>The Mood-o-Meter tracks employee energy levels, reducing turnover by up to 10%</h3>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid size={{ xs: 3.8, sm: 4, md: 4 }}>
                    <Card variant='outlined' style={{ backgroundColor: '#ecf8f2', border: 'none', borderRadius: 10, textAlign: 'left', marginLeft: 3 }} sx={{ ':hover': { boxShadow: 20, }, }}>
                        <CardContent style={{ padding: 10 }}><img src={imgManagement} alt={'Simplified Management'} style={{ width: 50 }} /></CardContent>
                        <CardContent style={{ minHeight: 150 }}>
                            <Typography style={{ textAlign: 'left', fontSize: 25, fontWeight: 600, color: '#3B83BD' }}>Simplified Management</Typography>
                            <h3 style={{ marginBlockEnd: 0, marginBlockStart: 0, fontWeight: 400, fontSize: 17 }}>Manage multi-location teams with ease, reducing management overhead by 30%.</h3>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid size={{ xs: 3.8, sm: 4, md: 4 }}>
                    <Card variant='outlined' style={{ backgroundColor: '#f3f9ff', border: 'none', borderRadius: 10, textAlign: 'left', marginLeft: 3 }} sx={{ ':hover': { boxShadow: 20, }, }}>
                        <CardContent style={{ padding: 10 }}><img src={imgConfiguration} alt={'Flexible Configuration'} style={{ width: 50 }} /></CardContent>
                        <CardContent style={{ minHeight: 150 }}>
                            <Typography style={{ textAlign: 'left', fontSize: 25, fontWeight: 600, color: '#3B83BD' }}>Flexible Configuration</Typography>
                            <h3 style={{ marginBlockEnd: 0, marginBlockStart: 0, fontWeight: 400, fontSize: 17 }}>Momentum’s setup reduces configuration time by 40%, allowing for faster deployment.</h3>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            <div style={{ backgroundColor: '#f7f8fd', paddingTop: 10, paddingBottom: 50 }} id="solutions">
                <Typography style={{ fontSize: 30, fontWeight: 600, color: '#3B83BD', paddingTop: 50, paddingBottom: 50, textAlign: 'center' }}>Momentum Capabilities: The Tools to Drive Your Success</Typography>
                <Grid container style={{ justifyContent: 'center', paddingLeft: 2 }} spacing={{ xs: 1, md: 1 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                    <Grid size={{ xs: 3.8, sm: 4, md: 4 }}>
                        <Card variant='outlined' style={{ borderColor: '#f7f8fd', borderRadius: 10, position: 'relative', minHeight: 230 }} sx={{ ':hover': { boxShadow: 20, }, }}>
                            <CardHeader avatar={<Avatar sx={{ backgroundColor: '#3B83BD', width: 45, height: 45 }}><AccessTime sx={{ color: 'white', fontSize: 30 }} /></Avatar>} title={<Typography style={{ fontSize: 25, fontWeight: 600 }}>Activity Recording</Typography>} />
                            <CardContent>
                                <h3 style={{ marginBlockEnd: 0, marginBlockStart: 0, fontWeight: 400, fontSize: 18, color: 'gray' }}>Track all user activity automatically and manually, including idle time, ensuring complete coverage.</h3>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid size={{ xs: 3.8, sm: 4, md: 4 }}>
                        <Card variant='outlined' style={{ borderColor: '#f7f8fd', borderRadius: 10, position: 'relative', minHeight: 230 }} sx={{ ':hover': { boxShadow: 20, }, }}>
                            <CardHeader avatar={<Avatar sx={{ backgroundColor: '#3B83BD', width: 45, height: 45 }}><Scale sx={{ color: 'white', fontSize: 30 }} /></Avatar>} title={<Typography style={{ fontSize: 25, fontWeight: 600 }}>Performance Monitoring</Typography>} />
                            <CardContent>
                                <h3 style={{ marginBlockEnd: 0, marginBlockStart: 0, fontWeight: 400, fontSize: 18, color: 'gray' }}>Monitor employee, team, and account performance with real-time dashboards and intraday analysis.</h3>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid size={{ xs: 3.8, sm: 4, md: 4 }}>
                        <Card variant='outlined' style={{ borderColor: '#f7f8fd', borderRadius: 10, position: 'relative', minHeight: 230 }} sx={{ ':hover': { boxShadow: 20, }, }}>
                            <CardHeader avatar={<Avatar sx={{ backgroundColor: '#3B83BD', width: 45, height: 45 }}><PermPhoneMsg sx={{ color: 'white', fontSize: 30 }} /></Avatar>} title={<Typography style={{ fontSize: 25, fontWeight: 600 }}>Multi-Channel Support</Typography>} />
                            <CardContent>
                                <h3 style={{ marginBlockEnd: 0, marginBlockStart: 0, fontWeight: 400, fontSize: 18, color: 'gray' }}>Track performance across voice, chat, and back-office activities, improving service consistency by 20%.</h3>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid size={{ xs: 3.8, sm: 4, md: 4 }}>
                        <Card variant='outlined' style={{ borderColor: '#f7f8fd', borderRadius: 10, position: 'relative', minHeight: 230 }} sx={{ ':hover': { boxShadow: 20, }, }}>
                            <CardHeader avatar={<Avatar sx={{ backgroundColor: '#3B83BD', width: 45, height: 45 }}><Dashboard sx={{ color: 'white', fontSize: 30 }} /></Avatar>} title={<Typography style={{ fontSize: 25, fontWeight: 600 }}>Dashboards & Reports</Typography>} />
                            <CardContent>
                                <h3 style={{ marginBlockEnd: 0, marginBlockStart: 0, fontWeight: 400, fontSize: 18, color: 'gray' }}>Generate detailed reports on productivity, activity types, shrinkage, and adherence.</h3>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid size={{ xs: 3.8, sm: 4, md: 4 }}>
                        <Card variant='outlined' style={{ borderColor: '#f7f8fd', borderRadius: 10, position: 'relative', minHeight: 230 }} sx={{ ':hover': { boxShadow: 20, }, }}>
                            <CardHeader avatar={<Avatar sx={{ backgroundColor: '#3B83BD', width: 45, height: 45 }}><SentimentSatisfied sx={{ color: 'white', fontSize: 30 }} /></Avatar>} title={<Typography style={{ fontSize: 25, fontWeight: 600 }}>Mood-o-Meter</Typography>} />
                            <CardContent>
                                <h3 style={{ marginBlockEnd: 0, marginBlockStart: 0, fontWeight: 400, fontSize: 18, color: 'gray' }}>Keep a pulse on team morale and energy levels to foster a positive and productive work environment.</h3>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid size={{ xs: 3.8, sm: 4, md: 4 }}>
                        <Card variant='outlined' style={{ borderColor: '#f7f8fd', borderRadius: 10, position: 'relative', minHeight: 230 }} sx={{ ':hover': { boxShadow: 20, }, }}>
                            <CardHeader avatar={<Avatar sx={{ backgroundColor: '#3B83BD', width: 45, height: 45 }}><Settings sx={{ color: 'white', fontSize: 30 }} /></Avatar>} title={<Typography style={{ fontSize: 25, fontWeight: 600 }}>System Setup</Typography>} />
                            <CardContent>
                                <h3 style={{ marginBlockEnd: 0, marginBlockStart: 0, fontWeight: 400, fontSize: 18, color: 'gray' }}>Quickly configure work types, activities, and user roles, enabling faster implementation.</h3>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </div>
            <h1 style={{ marginBlockEnd: 0, marginBlockStart: 0, color: '#3B83BD', fontSize: 35, fontWeight: 700, paddingTop: 50, textAlign: 'center', backgroundColor: '#f7f8fd' }}>Ready to Optimize Your Workforce?</h1>
            <div id="ready" style={{ justifyContent: 'center', textAlign: 'center', backgroundColor: '#f7f8fd' }}>
                <Button variant='contained' disableElevation style={{ textTransform: 'none', fontSize: 25, fontWeight: 500, marginTop: 10, marginBottom: 30, textAlign: 'center', justifySelf: 'center' }} onClick={(x) => setOpenForm(true)}>Get Started Today</Button>
            </div>

            <Dialog open={openForm} fullWidth={true} style={{ padding: 0, }} maxWidth={'md'} disableEscapeKeyDown>
                <Card>
                    <CardHeader style={{ padding: 6, paddingLeft: 10, paddingRight: 10, backgroundColor: '#e6e6e6' }} title={'Get document'} titleTypographyProps={{ variant: 'body1' }} action={<IconButton aria-label="Close" onClick={() => setOpenForm(false)}><Close color='error' /></IconButton>} />
                    <Divider />
                    <div style={{ backgroundColor: '#F7F8FD', textAlign: 'center', height: 50 }}>
                        <Typography style={{ color: '#2e2e2e', fontSize: 20, fontWeight: 500, paddingTop: 10 }}>Get the <strong>Momentum</strong> document on your email id.</Typography>
                    </div>
                    <UserForm page='Momentum' action='Send Email' />
                </Card>
            </Dialog>
        </>
    );
}