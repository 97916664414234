import React, { useState } from 'react';
import { TextField, Typography, Card, CardContent, CardHeader } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { IsNullOrEmptyOrUndefined, ValidateEmail } from '../Utilities/String';
import { CustomAlert } from '../Utilities/CustomAlert'

export function UserForm(props) {

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [mobile, setMobile] = useState('')
    const [message, setMessage] = useState('')
    const [loading, setLoading] = React.useState(false);


    const ValidateForm = () => {
        if (IsNullOrEmptyOrUndefined(name.trim()) || IsNullOrEmptyOrUndefined(email.trim()) || IsNullOrEmptyOrUndefined(message.trim())) {
            CustomAlert('Please enter all madatory fields.', 'error')
            return false;
        }
        if (name.trim().length > 50 || name.trim().length === 0) {
            CustomAlert('Please enter name within 50 char.', 'error')
            return false;
        }
        if (email.trim().length > 100 || email.trim().length === 0) {
            CustomAlert('Please enter email within 100 char.', 'error')
            return false;
        }
        if (ValidateEmail(email.trim()) === false) {
            CustomAlert('Please enter valid email.', 'error')
            return false;
        }
        if (mobile.trim().length > 20) {
            CustomAlert('Please enter mobile within 20 char.', 'error')
            return false;
        }
        if (message.trim().length > 200 || message.trim().length === 0) {
            CustomAlert('Please enter message within 200 char.', 'error')
            return false;
        }
        return true;
    }
    const ResetForm = () => {
        setName('');
        setEmail('');
        setMobile('');
        setMessage('');
    }
    const Save = () => {
        var isvalid = ValidateForm();
        if (isvalid) {
            setLoading(true)

            const headers = new Headers();
            headers.set('Accept', 'application/json, text/plain');
            headers.set('Content-Type', 'application/json;charset=UTF-8');
            var action = props.action === 'Submit' ? 'Save' : 'Send';

            var datamodel = { "name": name, "email": email, "mobile": mobile, "message": message, "page": props.page }
            var url = 'https://contactbyuser.azurewebsites.net/api/Contact/Insert'  // 'https://localhost:7190/api/Contact/Insert'

            fetch(url, { method: "POST", headers: headers, body: JSON.stringify(datamodel) })
                .then((response) =>
                    response.json()
                )
                .then(data => {
                    setLoading(false)
                    if (data.message === 'success') CustomAlert('Message has been successfully submitted', 'success')
                    else CustomAlert(data.message, 'error')
                    ResetForm();
                }).catch(e => {
                    setLoading(false)
                    CustomAlert(e.message, 'error')
                });

        }

    }

    return (
        <>

            <div style={{ textAlign: 'center', justifyContent: 'center', marginTop: 20, marginBottom: 20, marginLeft: '10%', width: '80%' }}>
                <Card variant='outlined'>
                    <CardHeader style={{ padding: 2 }} title={<Typography style={{ color: '#2e2e2e', fontSize: 16 }}>Fill the form with relevent data</Typography>} />

                    <CardContent>
                        <TextField style={{ width: '100%', marginBottom: 10 }} inputProps={{ maxLength: 50, style: { fontSize: 13 } }} required label="FullName" variant="outlined" value={name} onChange={(o) => setName(o.target.value)} />
                        <TextField style={{ width: '100%', marginBottom: 10 }} inputProps={{ maxLength: 100, style: { fontSize: 13 } }} required label="Email" variant="outlined" value={email} onChange={(o) => setEmail(o.target.value)} />
                        <TextField style={{ width: '100%', marginBottom: 10 }} inputProps={{ maxLength: 20, style: { fontSize: 13 } }} label="Mobile" variant="outlined" value={mobile} onChange={(o) => setMobile(o.target.value)} />
                        <TextField style={{ width: '100%', marginBottom: 10 }} inputProps={{ maxLength: 200, style: { fontSize: 13 } }} required multiline rows={2} label="Message" variant="outlined" value={message} onChange={(o) => setMessage(o.target.value)} />
                        <br />
                        <LoadingButton style={{ textTransform: 'none', width: 130, justifySelf: 'left' }} loading={loading} disableElevation variant="outlined" onClick={Save}>{props.action}</LoadingButton>

                    </CardContent>
                </Card>
            </div >
        </>
    );

}
