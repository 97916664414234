import React, { useEffect } from 'react';
import { Close, DataUsage, Feed, Schedule, AppsOutlined, Dashboard, Insights } from '@mui/icons-material';
import { Divider, Dialog, IconButton, Typography, Button, Card, CardContent, CardHeader } from '@mui/material';
import Grid from '@mui/material/Grid2';
import imgWastage from '../Images/Wastage.png'
import imgEmpowerment from '../Images/Empowerment.png'
import imgWorkLifeBalance from '../Images/WorkLifeBalance.png'
import imgProductivity from '../Images/Productivity.png'
import imgDecision from '../Images/decision-making.png'
import imgManagement from '../Images/Management.png'
import '../style.css'
import { UserForm } from './UserForm';
import { Helmet } from 'react-helmet';

export function Clarity() {
    //document.title = "Clarity: A Desktop Analytics Tool for attendance management, monitoring, utilization and productivity";

    const [openForm, setOpenForm] = React.useState(false);
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [])

    return (
        <>
            <Helmet>
                <title>Clarity - Advanced Desktop Analytics for Smarter Decisions</title>
                <meta name="description" content="Clarity is an advanced desktop analytics solution designed to help businesses make smarter, data-driven decisions. Unlock insights, optimize performance, and stay ahead with powerful analytics tools." />
                <meta name="keywords" content="Desktop Analytics Solution, Advanced Data Visualization, Business Intelligence Software, Data Analysis Tools, Decision-Making Software, Business Analytics Platform, Performance Optimization Software, Productivity Enhancement Tools, Data-Driven Decision Making, Analytics for Business Growth, Real-Time Data Insights." />
                <link rel="canonical" href="https://aarohasolutions.com/clarity"></link>
                <meta property="og:url" content="https://aarohasolutions.com/clarity" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Clarity - Advanced Desktop Analytics for Smarter Business Decisions" />
                <meta property="og:site_name" content="Aaroha Solutions Clarity" />
                <meta property="og:description" content="Clarity desktop analytics software empowers businesses with actionable insights, optimizing performance through advanced data analysis and visualization tools." />
                <meta property="og:image" content="https://aarohasolutions.com/static/media/clarity-logo-no-background.f6a99eae40dd32bbb4e8.png" />
                <meta property="og:image:width" content="2400" />
                <meta property="og:image:height" content="720" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Clarity - Smarter Decisions with Advanced Desktop Analytics" />
                <meta name="twitter:description" content="Empower your business with Clarity’s desktop analytics software. Get actionable insights and optimize performance effortlessly." />
                <meta name="twitter:image" content="https://aarohasolutions.com/static/media/clarity-logo-no-background.f6a99eae40dd32bbb4e8.png" />
            </Helmet>
            <div className='claritywithoutbackgroudimage' style={{ textAlign: 'center', backgroundColor: '#f7f8fd', paddingTop: 40, paddingBottom: 40, paddingLeft: 20 }}>
                <h1 style={{ marginBlockEnd: 0, marginBlockStart: 0, fontSize: 45, fontWeight: 700, color: '#3B83BD', paddingTop: 50, textAlign: 'left' }}>See the Bigger Picture, Work Smarter</h1>
                <h2 style={{ marginBlockEnd: 0, marginBlockStart: 0, fontSize: 18, fontWeight: 500, color: '#3B83BD', paddingTop: 20, textAlign: 'left' }}>Transform your workday with data-driven insights and measurable improvements.</h2>
                <a href="#ready"><Button variant='contained' disableElevation style={{ textTransform: 'none', fontSize: 25, fontWeight: 500, marginTop: 20 }}>Get Started</Button></a>
            </div>

            <h1 style={{ marginBlockEnd: 0, marginBlockStart: 0, fontSize: 30, fontWeight: 700, color: '#3B83BD', paddingTop: 40, paddingBottom: 40, textAlign: 'center' }}>Why Choose Clarity? Unlock the Potential of Your Workforce</h1>
            <Grid container style={{ marginLeft: '10%', marginRight: '10%', justifyContent: 'center', textAlign: 'center', paddingLeft: 2, paddingRight: 2, paddingBottom: 55 }} spacing={{ xs: 1, md: 1 }} columns={{ xs: 3, sm: 6, md: 12 }}>
                <Grid size={{ xs: 4, sm: 4, md: 4 }}>
                    <Card variant='outlined' style={{ borderRadius: 10, textAlign: 'left', backgroundColor: '#F3F9FF', border: 'none' }} sx={{ ':hover': { boxShadow: 20, }, }}>
                        <CardContent style={{ padding: 10 }}><img src={imgEmpowerment} alt={'Empowerment Through Insight'} style={{ width: 50, height: 50 }} /></CardContent>
                        <CardContent style={{ minHeight: 130 }}>
                            <Typography style={{ textAlign: 'left', fontSize: 22, fontWeight: 550, color: '#3B83BD' }}>Empowerment Through Insight</Typography>
                            <Typography style={{ fontSize: 14, paddingTop: 10 }}>Track time spent on different applications and identify inefficiencies with up to 40% more visibility.</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid size={{ xs: 4, sm: 4, md: 4 }}>
                    <Card variant='outlined' style={{ borderRadius: 10, textAlign: 'left', marginLeft: 3, backgroundColor: '#FEF6EC', border: 'none' }} sx={{ ':hover': { boxShadow: 20, }, }}>
                        <CardContent style={{ padding: 10 }}><img src={imgProductivity} alt={'Boost Productivity and Efficiency'} style={{ width: 50, height: 50 }} /></CardContent>
                        <CardContent style={{ minHeight: 130 }}>
                            <Typography style={{ textAlign: 'left', fontSize: 22, fontWeight: 550, color: '#3B83BD', }}>Boost Productivity and Efficiency</Typography>
                            <Typography style={{ fontSize: 14, paddingTop: 10 }}>Improve personal productivity by up to 30% by optimizing workflows and minimizing distractions.</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid size={{ xs: 4, sm: 4, md: 4 }}>
                    <Card variant='outlined' style={{ borderRadius: 10, textAlign: 'left', marginLeft: 3, backgroundColor: '#FCEEEF', border: 'none' }} sx={{ ':hover': { boxShadow: 20, }, }}>
                        <CardContent style={{ padding: 10 }}><img src={imgWastage} alt={'Reduce Software Wastage'} style={{ width: 50, height: 50 }} /></CardContent>
                        <CardContent style={{ minHeight: 130 }}>
                            <Typography style={{ textAlign: 'left', fontSize: 22, fontWeight: 550, color: '#3B83BD', }}>Reduce Software Wastage</Typography>
                            <Typography style={{ fontSize: 14, paddingTop: 10 }}>Cut unnecessary software licenses by 37% through detailed application usage tracking.</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid size={{ xs: 4, sm: 4, md: 4 }}>
                    <Card variant='outlined' style={{ borderRadius: 10, textAlign: 'left', marginLeft: 3, backgroundColor: '#F1F3F5', border: 'none' }} sx={{ ':hover': { boxShadow: 20, }, }}>
                        <CardContent style={{ padding: 10 }}><img src={imgWorkLifeBalance} alt={'Promote Employee Well-Being'} style={{ width: 50, height: 50 }} /></CardContent>
                        <CardContent style={{ minHeight: 165 }}>
                            <Typography style={{ textAlign: 'left', fontSize: 22, fontWeight: 550, color: '#3B83BD' }}>Promote Employee Well-Being</Typography>
                            <Typography style={{ fontSize: 14, paddingTop: 10 }}>Help to prevent burnout and promote a healthier work-life balance by reducing continuous screen time by 25%.</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid size={{ xs: 4, sm: 4, md: 4 }}>
                    <Card variant='outlined' style={{ borderRadius: 10, textAlign: 'left', marginLeft: 3, backgroundColor: '#FCEEEF', border: 'none' }} sx={{ ':hover': { boxShadow: 20, }, }}>
                        <CardContent style={{ padding: 10 }}><img src={imgManagement} alt={'Enhanced Team Management'} style={{ width: 50, height: 50 }} /></CardContent>
                        <CardContent style={{ minHeight: 165 }}>
                            <Typography style={{ textAlign: 'left', fontSize: 22, fontWeight: 550, color: '#3B83BD' }}>Enhanced Team Management</Typography>
                            <Typography style={{ fontSize: 14, paddingTop: 10 }}>For managers, Clarity aggregates individual data into team-level reports, providing 15-20% higher productivity insights.</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid size={{ xs: 4, sm: 4, md: 4 }}>
                    <Card variant='outlined' style={{ borderRadius: 10, textAlign: 'left', marginLeft: 3, backgroundColor: '#F1F3F5', border: 'none' }} sx={{ ':hover': { boxShadow: 20, }, }}>
                        <CardContent style={{ padding: 10 }}><img src={imgDecision} alt={'Data-Driven Decisions for Long-Term Success'} style={{ width: 50, height: 50 }} /></CardContent>
                        <CardContent style={{ minHeight: 165 }}>
                            <Typography style={{ textAlign: 'left', fontSize: 22, fontWeight: 550, color: '#3B83BD' }}>Data-Driven Decisions for Long-Term Success</Typography>
                            <Typography style={{ fontSize: 14, paddingTop: 10 }}>Clarity’s customizable reports and analytics dashboards give individuals and teams the ability to make decisions that lead to quantifiable improvements.</Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            <div style={{ backgroundColor: '#f7f8fd', paddingTop: 10, paddingBottom: 50 }}>
                <h1 style={{ marginBlockEnd: 0, marginBlockStart: 0, fontSize: 30, fontWeight: 700, color: '#3B83BD', paddingTop: 30, paddingBottom: 30, textAlign: 'center' }}>Clarity's Core Features: Powering Smarter Workforce Management</h1>
                {/* <Typography style={{ fontSize: 20, fontWeight: 550, color: '#3B83BD', paddingBottom: 50, textAlign: 'center' }}>The core to improve employee's Productivity and Utilization</Typography> */}

                <Grid container style={{ justifyContent: 'center', paddingLeft: 2 }} spacing={{ xs: 1, md: 1 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                    <Grid size={{ xs: 4, sm: 4, md: 4 }}>
                        <Card variant='outlined' style={{ display: 'flex', flexDirection: 'column', borderColor: '#f7f8fd', borderRadius: 10, position: 'relative', minHeight: 200 }} sx={{ ':hover': { boxShadow: 20, }, }}>
                            <CardHeader avatar={<Insights sx={{ fontSize: 40 }} color='primary' />} title={<Typography style={{ fontSize: 25, fontWeight: 600 }}>Performance Insights</Typography>} />
                            <CardContent style={{ bottom: 0, height: '100%' }}>
                                <Typography style={{ fontSize: 15, color: 'gray' }}>Gain visibility into team performance trends, optimize resource allocation, and ensure accurate time tracking for remote and in-office teams, boosting productivity by up to 15%.</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid size={{ xs: 4, sm: 4, md: 4 }}>
                        <Card variant='outlined' style={{ display: 'flex', flexDirection: 'column', borderColor: '#f7f8fd', borderRadius: 10, position: 'relative', minHeight: 200 }} sx={{ ':hover': { boxShadow: 20, }, }}>
                            <CardHeader avatar={<Schedule sx={{ fontSize: 40 }} color='primary' />} title={<Typography style={{ fontSize: 25, fontWeight: 600 }}>Active & Idle Time Tracking</Typography>} />
                            <CardContent>
                                <Typography style={{ fontSize: 15, color: 'gray' }}>Gain accurate insights into how much time is spent on work versus idle time, improving active working hours by up to 25%.</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid size={{ xs: 4, sm: 4, md: 4 }}>
                        <Card variant='outlined' style={{ display: 'flex', flexDirection: 'column', borderColor: '#f7f8fd', borderRadius: 10, position: 'relative', minHeight: 200 }} sx={{ ':hover': { boxShadow: 20, }, }}>
                            <CardHeader avatar={<DataUsage sx={{ fontSize: 40 }} color='primary' />} title={<Typography style={{ fontSize: 25, fontWeight: 600 }}>Application Usage Insights</Typography>} />
                            <CardContent>
                                <Typography style={{ fontSize: 15, color: 'gray' }} color='primary'>Monitor application usage to identify time sinks, helping reduce non-essential tasks by 20%.</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid size={{ xs: 4, sm: 4, md: 4 }}>
                        <Card variant='outlined' style={{ display: 'flex', flexDirection: 'column', borderColor: '#f7f8fd', borderRadius: 10, position: 'relative', minHeight: 200 }} sx={{ ':hover': { boxShadow: 20, }, }}>
                            <CardHeader avatar={<Dashboard sx={{ fontSize: 40 }} color='primary' />} title={<Typography style={{ fontSize: 25, fontWeight: 600 }}>Analytics Dashboards</Typography>} />
                            <CardContent style={{ bottom: 0, height: '100%' }}>
                                <Typography style={{ fontSize: 15, color: 'gray' }}>Generate reports that help you make data-driven decisions and improve productivity.</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid size={{ xs: 4, sm: 4, md: 4 }}>
                        <Card variant='outlined' style={{ display: 'flex', flexDirection: 'column', borderColor: '#f7f8fd', borderRadius: 10, position: 'relative', minHeight: 200 }} sx={{ ':hover': { boxShadow: 20, }, }}>
                            <CardHeader avatar={<Feed sx={{ fontSize: 40 }} color='primary' />} title={<Typography style={{ fontSize: 25, fontWeight: 600 }}>System Event Logging</Typography>} />
                            <CardContent>
                                <Typography style={{ fontSize: 15, color: 'gray' }}>Clarity tracks key system events—log-ins, log-outs, lock, unlock, shutdown, and restarts—helping users understand how much time is actually spent on the computer.</Typography>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid size={{ xs: 4, sm: 4, md: 4 }}>
                        <Card variant='outlined' style={{ display: 'flex', flexDirection: 'column', borderColor: '#f7f8fd', borderRadius: 10, position: 'relative', minHeight: 200 }} sx={{ ':hover': { boxShadow: 20, }, }}>
                            <CardHeader avatar={<AppsOutlined sx={{ fontSize: 40 }} color='primary' />} title={<Typography style={{ fontSize: 25, fontWeight: 600 }}>Categorization of Applications</Typography>} />
                            <CardContent style={{ bottom: 0, height: '100%' }}>
                                <Typography style={{ fontSize: 15, color: 'gray' }}>By monitoring categorized app usage, users reduce time spent on non-essential tasks by 20%, increasing productivity and focus on important tasks.</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </div>

            {/* <div style={{ paddingTop: 10, paddingBottom: 50, marginLeft: '10%', marginRight: '10%' }}>
                <Typography style={{ fontSize: 30, fontWeight: 600, color: '#3B83BD', paddingTop: 50, paddingBottom: 30, textAlign: 'center' }}>What you get from Clarity</Typography>
                <Grid container style={{ justifyContent: 'center', paddingLeft: 2 }} spacing={{ xs: 1, md: 1 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                    <Grid size={{ xs: 4, sm: 4, md: 4 }}>
                        <Card variant='outlined' style={{ border: 'none', backgroundColor: '#FDF7E6', borderRadius: 10, position: 'relative', minHeight: 200 }}>
                            <CardHeader avatar={<SupervisedUserCircle sx={{ fontSize: 40 }} color='primary' />} title={<Typography style={{ fontSize: 20, fontWeight: 550 }}>Active time increase</Typography>} />
                            <CardContent>
                                <Typography style={{ fontSize: 15, color: 'text.secondary' }}>Clarity contributes to increase employees' active screen time by offering realtime feedback.</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid size={{ xs: 4, sm: 4, md: 4 }}>
                        <Card variant='outlined' style={{ border: 'none', backgroundColor: '#FCEEEF', borderRadius: 10, position: 'relative', minHeight: 200 }}>
                            <CardHeader avatar={<VerticalAlignBottom sx={{ fontSize: 40 }} color='primary' />} title={<Typography style={{ fontSize: 20, fontWeight: 550 }}>Minimize costs</Typography>} />
                            <CardContent>
                                <Typography style={{ fontSize: 15, color: 'text.secondary' }}>Clarity enable the company to stand out from the competition by significantly enhancing productivity&utilization.</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid size={{ xs: 4, sm: 4, md: 4 }}>
                        <Card variant='outlined' style={{ border: 'none', backgroundColor: '#F1F3F5', borderRadius: 10, position: 'relative', minHeight: 200 }}>
                            <CardHeader avatar={<Balance sx={{ fontSize: 40 }} color='primary' />} title={<Typography style={{ fontSize: 20, fontWeight: 550 }}>Workload balance</Typography>} />
                            <CardContent>
                                <Typography style={{ fontSize: 15, color: 'text.secondary' }}>Clarity assist to find the imbalance indicators like the multitasking and overtime.</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid size={{ xs: 4, sm: 4, md: 4 }}>
                        <Card variant='outlined' style={{ border: 'none', backgroundColor: '#ECF8F2', borderRadius: 10, position: 'relative', minHeight: 200 }}>
                            <CardHeader avatar={<PlaylistAdd sx={{ fontSize: 40 }} color='primary' />} title={<Typography style={{ fontSize: 20, fontWeight: 550 }}>Instant utilization improvement</Typography>} />
                            <CardContent>
                                <Typography style={{ fontSize: 15, color: 'text.secondary' }}>Seamlessly integrate Precisely with existing systems for consistent quality management across locations.</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid size={{ xs: 4, sm: 4, md: 4 }}>
                        <Card variant='outlined' style={{ border: 'none', backgroundColor: '#F3F9FF', borderRadius: 10, position: 'relative', minHeight: 200 }}>
                            <CardHeader avatar={<MonetizationOn sx={{ fontSize: 40 }} color='primary' />} title={<Typography style={{ fontSize: 20, fontWeight: 550 }}>Increase in Profitability</Typography>} />
                            <CardContent>
                                <Typography style={{ fontSize: 15, color: 'text.secondary' }}>The data-driven approach offers to make informed decisions, optimize pricing strategies, minimize wastage.</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid size={{ xs: 4, sm: 4, md: 4 }}>
                        <Card variant='outlined' style={{ border: 'none', backgroundColor: '#FEF6EC', borderRadius: 10, position: 'relative', minHeight: 200 }}>
                            <CardHeader avatar={<DataUsage sx={{ fontSize: 40 }} color='primary' />} title={<Typography style={{ fontSize: 20, fontWeight: 550 }}>Socially responsible use of data</Typography>} />
                            <CardContent>
                                <Typography style={{ fontSize: 15, color: 'text.secondary' }}>Clarity respect employees's privacy and It takes center stage in the design of data policies and practices.</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </div>

            <div style={{ paddingTop: 10, paddingBottom: 50, marginLeft: '10%', marginRight: '10%' }} id="solutions">
                <Typography style={{ fontSize: 30, fontWeight: 600, color: '#3B83BD', paddingTop: 50, paddingBottom: 30, textAlign: 'center' }}>Start your journey to get benefited!</Typography>
                <Grid container style={{ justifyContent: 'center', paddingLeft: 2 }} spacing={{ xs: 1, md: 1 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                    <Grid size={{ xs: 4, sm: 3, md: 3 }}>
                        <Card variant='outlined' style={{ border: 'none', backgroundColor: '#e06c6c', borderRadius: 10, position: 'relative', minHeight: 200 }}>
                            <CardHeader avatar={<Filter1Rounded sx={{ fontSize: 40 }} color='error' />} title={<Typography style={{ fontSize: 20, fontWeight: 550 }}>Month 1</Typography>} />
                            <CardContent>
                                <Typography style={{ fontSize: 18, color: 'text.secondary' }}>Boost employee attendance instantly.</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid size={{ xs: 4, sm: 3, md: 3 }}>
                        <Card variant='outlined' style={{ border: 'none', backgroundColor: '#ce6ddf', borderRadius: 10, position: 'relative', minHeight: 200 }}>
                            <CardHeader avatar={<Filter2Rounded sx={{ fontSize: 40 }} color='secondary' />} title={<Typography style={{ fontSize: 20, fontWeight: 550 }}>Month 2</Typography>} />
                            <CardContent>
                                <Typography style={{ fontSize: 18, color: 'text.secondary' }}>Keep employee motivated.</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid size={{ xs: 4, sm: 3, md: 3 }}>
                        <Card variant='outlined' style={{ border: 'none', backgroundColor: '#5fa6ec', borderRadius: 10, position: 'relative', minHeight: 200 }}>
                            <CardHeader avatar={<Filter3Rounded sx={{ fontSize: 40 }} color='primary' />} title={<Typography style={{ fontSize: 20, fontWeight: 550 }}>Month 3</Typography>} />
                            <CardContent>
                                <Typography style={{ fontSize: 18, color: 'text.secondary' }}>Build a sustainable team.</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid size={{ xs: 4, sm: 3, md: 3 }}>
                        <Card variant='outlined' style={{ border: 'none', backgroundColor: '#6ac86f', borderRadius: 10, position: 'relative', minHeight: 200 }}>
                            <CardHeader avatar={<Filter9PlusRounded sx={{ fontSize: 40 }} color='success' />} title={<Typography style={{ fontSize: 20, fontWeight: 550 }}>An year</Typography>} />
                            <CardContent>
                                <Typography style={{ fontSize: 18, color: 'text.secondary' }}>Start creating optimation plan.</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </div> */}

            <h1 style={{ marginBlockEnd: 0, marginBlockStart: 0, fontSize: 35, fontWeight: 700, paddingTop: 50, textAlign: 'center', backgroundColor: '#f7f8fd' }}>Ready to Work Smarter with Clarity?</h1>
            <h1 style={{ marginBlockEnd: 0, marginBlockStart: 0, fontWeight: 400, fontSize: 15, paddingTop: 30, textAlign: 'center', backgroundColor: '#f7f8fd', paddingLeft: '10%', paddingRight: '10%' }}>With Clarity, users see measurable improvements in productivity, time management, work-life balance, and reduced software wastage. Its comprehensive feature set and privacy-first design make it the ideal solution for individuals, teams, and businesses seeking to optimize their time, reduce costs, and safeguard employee well-being.</h1>
            <h1 style={{ marginBlockEnd: 0, marginBlockStart: 0, fontWeight: 400, fontSize: 15, paddingTop: 10, textAlign: 'center', backgroundColor: '#f7f8fd', paddingLeft: '10%', paddingRight: '10%' }}><strong>Contact us today</strong> to schedule a demo and discover how Clarity can transform your workforce management and help you work smarter.</h1>


            <div id="ready" style={{ justifyContent: 'center', textAlign: 'center', backgroundColor: '#f7f8fd' }}>
                <Button variant='contained' disableElevation style={{ textTransform: 'none', fontSize: 25, fontWeight: 500, marginTop: 10, marginBottom: 30, textAlign: 'center', justifySelf: 'center' }} onClick={(x) => setOpenForm(true)}>Get Started Today</Button>
            </div>

            <Dialog open={openForm} fullWidth={true} style={{ padding: 0, }} maxWidth={'md'} disableEscapeKeyDown>
                <Card>
                    <CardHeader style={{ padding: 6, paddingLeft: 10, paddingRight: 10, backgroundColor: '#e6e6e6' }} title={'Get document'} titleTypographyProps={{ variant: 'body1' }} action={<IconButton aria-label="Close" onClick={() => setOpenForm(false)}><Close color='error' /></IconButton>} />
                    <Divider />
                    <div style={{ backgroundColor: '#F7F8FD', textAlign: 'center', height: 50 }}>
                        <Typography style={{ color: '#2e2e2e', fontSize: 20, fontWeight: 500, paddingTop: 10 }}>Get the <strong>Clarity</strong> document on your email id.</Typography>
                    </div>

                    <UserForm page='Clarity' action='Send Email' />
                </Card>
            </Dialog>

        </>
    );
}