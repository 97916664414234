import React from 'react';
import { useNavigate } from 'react-router-dom';
import { KeyboardArrowDown, Speed, Verified, TimerOutlined, AccountTreeRounded } from '@mui/icons-material';
import { Button, AppBar, Toolbar, Box, Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { Link } from 'react-router-dom';
import './App.css';
import aarohalogo from './Images/aaroha-logo.png'


export function NavMenu(props) {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const [anchorElAbout, setAnchorElAbout] = React.useState(null);
    const openAbout = Boolean(anchorElAbout);
    const navigate = useNavigate();
    const isMobile = window.innerWidth <= 768;


    // const handleLogout = () => {
    //     props.instance.logoutRedirect({ postLogoutRedirectUri: "/", });
    // };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (btnClicked) => {

        if (btnClicked === 'Team')
            navigate('/Momentum');
        else if (btnClicked === 'Work')
            navigate('/Flow');
        else if (btnClicked === 'Quality')
            navigate('/Precisely');
        else if (btnClicked === 'Clarity')
            navigate('/Clarity');

        setAnchorEl(null);
    };
    const handleCloseAbout = (btnClicked) => {
        if (btnClicked === 'About')
            navigate('/About');
        else if (btnClicked === 'News')
            navigate('/News');

        setAnchorElAbout(null);
    };

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <AppBar position="static" elevation={0} sx={{ backgroundColor: '#3B83BD', padding: '10px', justifyContent: 'center', textAlign: 'center' }}>
                    <div style={{ textAlign: 'center' }}><img src={aarohalogo} alt={'aaroha logo'} style={{ width: 120, height: 40 }} /></div>
                    <Toolbar style={{ minHeight: 50, justifyContent: 'center', textAlign: 'center' }}>
                        <div style={{ marginLeft: 10 }}></div>
                        <Link to="/Home" style={{ textDecoration: 'none' }}><Button disableElevation style={{ textTransform: 'none', fontSize: 17, fontWeight: 600, color: 'white' }}>Home</Button></Link>
                        <div style={{ marginRight: 10 }}></div>

                        <Button disableElevation style={{ textTransform: 'none', fontSize: 17, fontWeight: 600, color: 'white' }} onClick={handleClick} endIcon={<KeyboardArrowDown />}>Products</Button>
                        <div style={{ marginRight: 10 }}></div>

                        {isMobile === false &&
                            <>
                                <Link to="/Industries" style={{ textDecoration: 'none' }}><Button disableElevation style={{ textTransform: 'none', fontSize: 17, fontWeight: 600, color: 'white' }}>Industries</Button></Link>
                                <div style={{ marginRight: 10 }}></div>
                            </>}

                        <Link to="/AboutUs" style={{ textDecoration: 'none' }}><Button disableElevation style={{ textTransform: 'none', fontSize: 17, fontWeight: 600, color: 'white' }}>Company Info</Button></Link>
                        <div style={{ marginRight: 10 }}></div>

                        <Link to="/Contactus" style={{ textDecoration: 'none' }}><Button disableElevation style={{ textTransform: 'none', fontSize: 17, fontWeight: 600, color: 'white' }}>Contact</Button></Link>
                        <div style={{ marginRight: 10 }}></div>

                    </Toolbar>
                </AppBar>
            </Box >

            <Menu elevation={0} sx={{ fontSize: 13, '& .MuiPaper-root': { border: '1px solid #d9d9d9' } }} anchorEl={anchorEl} open={open} onClose={handleClose} MenuListProps={{ 'aria-labelledby': 'basic-button', }}>
                <MenuItem onClick={() => handleClose('Work')}>
                    <ListItemIcon><AccountTreeRounded fontSize="small" color='primary' /></ListItemIcon>
                    <ListItemText>Flow</ListItemText>
                </MenuItem>

                <MenuItem onClick={() => handleClose('Team')}>
                    <ListItemIcon><TimerOutlined fontSize="small" color='primary' /></ListItemIcon>
                    <ListItemText>Momentum</ListItemText>
                </MenuItem>

                <MenuItem onClick={() => handleClose('Quality')}>
                    <ListItemIcon><Verified fontSize="small" color='primary' /></ListItemIcon>
                    <ListItemText>Precisely</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => handleClose('Clarity')}>
                    <ListItemIcon><Speed fontSize="small" color='primary' /></ListItemIcon>
                    <ListItemText>Clarity</ListItemText>
                </MenuItem>
            </Menu>

        </>
    );
}