import React, { Component } from 'react'
import { Container, Divider, Typography } from '@mui/material'
import { NavMenu } from './NavMenu';

export class Layout extends Component {
    static displayName = Layout.name;
    constructor(props) {
        super(props);
        this.state = {};
    }
    scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    render() {
        return (
            <div>
                <NavMenu />
                <Container style={{ maxWidth: 'inherit', padding: 0 }}>
                    {this.props.children}
                </Container>
                <Divider />
                <Typography sx={{ backgroundColor: '#f7f8fd', textAlign: 'center', height: 40, paddingTop: '15px', color: 'text.secondary', fontSize: 17 }} >© 2024 aaroha solutions. All rights reserved.</Typography>
            </div>
        );
    }
}
