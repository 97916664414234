export const IsNullOrEmptyOrUndefined = (value) => {
    if (value === null || value === undefined || value === '') {
        return true
    }
    else {
        return false
    }
}
export const TitleCase = (str) => {
    if (IsNullOrEmptyOrUndefined(str) === false) {
        str = str.toLowerCase().split(' ');
        for (var i = 0; i < str.length; i++) {
            str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
        }
        return str.join(' ');
    }
    else
        return '';
}

export const CountString = (str, letter) => {
    let count = 0;
    for (let i = 0; i < str.length; i++) {
        if (str.charAt(i) === letter) {
            count++;
        }
    }
    return count;
}

export const ValidateInput = (input) => {
    // Regular expression pattern: allows letters, numbers, spaces, plus, minus, underscore and dots
    const pattern = /^[A-Za-z0-9\s;_.+\\-]+$/;

    // Test if the input matches the pattern
    var IsMatch = pattern.test(input);
    return IsMatch
}
export const ValidateLoginInput = (input) => {
    // Regular expression pattern: allows letters, numbers, spaces, plus, minus, underscore and dots
    const pattern = /^[A-Za-z0-9@;_.+\\-]+$/;

    // Test if the input matches the pattern
    var IsMatch = pattern.test(input);
    return IsMatch
}
export const ValidateEmail = (email) => {
    // Regular expression pattern: Email
    const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    // Test if the input matches the pattern
    var IsMatch = pattern.test(email)
    return IsMatch
}

export const ValidateMultipleEmailInput = (input) => {
    // Regular expression pattern: allows letters, numbers, spaces, plus, minus, underscore and dots
    const pattern = /^[A-Za-z0-9\s@;_.+\\-]+$/;

    // Test if the input matches the pattern
    var IsMatch = pattern.test(input);
    return IsMatch
}

export const FirstLetters = (str) => {
    const words = str.split(' ');
    const firstLetters = words.map(word => word.charAt(0));
    var output = firstLetters.join('');
    if (output.length > 2) {
        output = output.substring(0, 2).toUpperCase();
    }
    return output.toUpperCase();
}

export const FormatFileSize = (bytes, decimalPoint) => {
    if (bytes === 0) return '0 Bytes';
    var k = 1000,
        dm = decimalPoint || 2,
        sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}