import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Card, CardContent, CardHeader } from '@mui/material';
import Grid from '@mui/material/Grid2';
import imgSupport from '../Images/Support.png'
import imgTraining from '../Images/Training.png'
import imgPartership from '../Images/Partership.png'
import imgIncomplete from '../Images/Incomplete.png'
import imgInconsistent from '../Images/Inconsistent.png'
import imgMissed from '../Images/Missed.png'
import imgFragmented from '../Images/Fragmented.png'
import imgTrendup from '../Images/TrendUp.png'
import imgOptimization from '../Images/Optimization.png'
import imgImprovement from '../Images/Continuous-improvement.png'

export function AboutUs() {
    document.title = "Aaroha Solutions: An Innovative software solution for your business";

    return (
        <>

            <div style={{ backgroundColor: '#51b8e1', paddingTop: 30, paddingBottom: 30 }}>
                <CardContent>
                    <h1 style={{ marginBlockEnd: 0, marginBlockStart: 0, color: 'white', fontSize: 30, paddingTop: 10, textAlign: 'center', fontFamily: 'Public Sans", sans-serif' }}>Welcome to Aaroha Solutions</h1>
                    <h3 style={{ marginBlockEnd: 0, marginBlockStart: 0, color: 'white', fontSize: 25, paddingTop: 10, textAlign: 'center', fontFamily: 'Public Sans", sans-serif', fontWeight: 400 }}>Maximize Your Business's Value with Aaroha’s Partnership</h3>
                    <h4 style={{ marginBlockEnd: 0, marginBlockStart: 0, color: 'white', fontSize: 15, paddingTop: 10, textAlign: 'center', fontFamily: 'Public Sans", sans-serif', fontWeight: 500 }}>Technology solutions are powerful tools for driving organization's success through productivity and efficiency—but too often, enterprises fail to unlock their full potential after implementation. Without the right approach, businesses miss out on key features, leaving processes fragmented and ROI unfulfilled.</h4>

                </CardContent>
            </div>

            <Typography style={{ fontSize: 30, fontWeight: 600, color: '#3B83BD', paddingTop: 40, paddingBottom: 20, textAlign: 'center', fontFamily: 'Montserrat' }}>Are these challenges holding you back?</Typography>

            <Grid container style={{ marginLeft: '5%', marginRight: '5%', justifyContent: 'center', textAlign: 'center', paddingLeft: 2, paddingRight: 2, paddingBottom: 55 }} spacing={{ xs: 1, md: 1 }} columns={{ xs: 3, sm: 6, md: 12 }}>
                <Grid size={{ xs: 3, sm: 3, md: 3 }}>
                    <Card variant='outlined' style={{ borderRadius: 10, textAlign: 'left', backgroundColor: '#F3F9FF', border: 'none' }} sx={{ ':hover': { boxShadow: 20, }, }}>
                        <CardHeader avatar={<img src={imgIncomplete} alt={'Incomplete Adoption'} style={{ width: 50, height: 50 }} />} />
                        <CardContent style={{ minHeight: 175, paddingTop: 5 }}>
                            <Typography style={{ textAlign: 'left', fontSize: 20, fontWeight: 550, color: '#3B83BD', fontFamily: 'Montserrat' }}>Incomplete Adoption</Typography>
                            <Typography style={{ fontSize: 14, paddingTop: 10 }}>Employees struggle to integrate new tools into daily workflows, leading to low engagement and missed opportunities for improvement.</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid size={{ xs: 3, sm: 3, md: 3 }}>
                    <Card variant='outlined' style={{ borderRadius: 10, textAlign: 'left', backgroundColor: '#FEF6EC', border: 'none' }} sx={{ ':hover': { boxShadow: 20, }, }}>
                        <CardHeader avatar={<img src={imgMissed} alt={'Missed Optimization Opportunities'} style={{ width: 50, height: 50 }} />} />
                        <CardContent style={{ minHeight: 175, paddingTop: 5 }}>
                            <Typography style={{ textAlign: 'left', fontSize: 20, fontWeight: 550, color: '#3B83BD', fontFamily: 'Montserrat' }}>Missed Optimization Opportunities</Typography>
                            <Typography style={{ fontSize: 14, paddingTop: 10 }}>Advanced features and analytics remain untapped, limiting potential productivity gains and workflow efficiency.</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid size={{ xs: 3, sm: 3, md: 3 }}>
                    <Card variant='outlined' style={{ borderRadius: 10, textAlign: 'left', backgroundColor: '#FCEEEF', border: 'none' }} sx={{ ':hover': { boxShadow: 20, }, }}>
                        <CardHeader avatar={<img src={imgFragmented} alt={'Fragmented Process Improvement'} style={{ width: 50, height: 50 }} />} />
                        <CardContent style={{ minHeight: 175, paddingTop: 5 }}>
                            <Typography style={{ textAlign: 'left', fontSize: 20, fontWeight: 550, color: '#3B83BD', fontFamily: 'Montserrat' }}>Fragmented Process Improvement</Typography>
                            <Typography style={{ fontSize: 14, paddingTop: 10 }}>Without a cohesive strategy, process improvements are uneven across departments, reducing the overall impact of the solution.</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid size={{ xs: 3, sm: 3, md: 3 }}>
                    <Card variant='outlined' style={{ borderRadius: 10, textAlign: 'left', backgroundColor: '#F1F3F5', border: 'none' }} sx={{ ':hover': { boxShadow: 20, }, }}>
                        <CardHeader avatar={<img src={imgInconsistent} alt={'Inconsistent Use Across Teams'} style={{ width: 50, height: 50 }} />} />
                        <CardContent style={{ minHeight: 175, paddingTop: 5 }}>
                            <Typography style={{ textAlign: 'left', fontSize: 20, fontWeight: 550, color: '#3B83BD', fontFamily: 'Montserrat' }}>Inconsistent Use Across Teams</Typography>
                            <Typography style={{ fontSize: 14, paddingTop: 10 }}>Different teams adopt the software at varying levels, resulting in siloed usage and suboptimal performance.</Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            <h1 style={{ marginBlockEnd: 0, marginBlockStart: 0, fontSize: 30, fontWeight: 700, color: '#3B83BD', paddingTop: 40, paddingBottom: 10, textAlign: 'center', fontFamily: 'Montserrat' }}>Aaroha’s Solution: Unlocking the Full Potential of Our Software</h1>
            <h6 style={{ marginBlockEnd: 0, marginBlockStart: 0, fontSize: 17, paddingTop: 10, paddingBottom: 20, textAlign: 'center', paddingLeft: '10%', paddingRight: '10%', fontFamily: 'Montserrat' }}>At Aaroha, we understand that true success doesn't end with implementation. Our partnership ensures your software drives meaningful, measurable improvements across your business. Here’s how we do it:</h6>

            <Grid container style={{ marginLeft: '5%', marginRight: '5%', justifyContent: 'center', textAlign: 'center', paddingLeft: 2, paddingRight: 2, paddingBottom: 55 }} spacing={{ xs: 1, md: 1 }} columns={{ xs: 3, sm: 6, md: 12 }}>
                <Grid size={{ xs: 3, sm: 3, md: 3 }}>
                    <Card variant='outlined' style={{ borderRadius: 10, textAlign: 'left', backgroundColor: '#F3F9FF', border: 'none' }} sx={{ ':hover': { boxShadow: 20, }, }}>
                        <CardHeader style={{ minHeight: 70, paddingBottom: 5 }} avatar={<img src={imgSupport} alt={'End-to-End Support'} style={{ width: 50 , height: 50}} />} title={<Typography style={{ textAlign: 'left', fontSize: 22, fontWeight: 550, color: '#3B83BD', fontFamily: 'Montserrat' }}>End-to-End Support</Typography>} />
                        <CardContent style={{ minHeight: 100, paddingTop: 5 }}>
                            <Typography style={{ fontSize: 14, paddingTop: 10 }}> We stay engaged well beyond the rollout to ensure full adoption and effective use.</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid size={{ xs: 3, sm: 3, md: 3 }}>
                    <Card variant='outlined' style={{ borderRadius: 10, textAlign: 'left', backgroundColor: '#FEF6EC', border: 'none' }} sx={{ ':hover': { boxShadow: 20, }, }}>
                        <CardHeader style={{ minHeight: 70, paddingBottom: 5 }} avatar={<img src={imgTraining} alt={'Tailored Training'} style={{ width: 50 , height: 50}} />} title={<Typography style={{ textAlign: 'left', fontSize: 22, fontWeight: 550, color: '#3B83BD', fontFamily: 'Montserrat' }}>Tailored Training</Typography>} />
                        <CardContent style={{ minHeight: 100, paddingTop: 5 }}>
                            <Typography style={{ fontSize: 14, paddingTop: 10 }}>Comprehensive, role-specific training programs drive adoption and help employees unlock advanced capabilities.</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid size={{ xs: 3, sm: 3, md: 3 }}>
                    <Card variant='outlined' style={{ borderRadius: 10, textAlign: 'left', backgroundColor: '#FCEEEF', border: 'none' }} sx={{ ':hover': { boxShadow: 20, }, }}>
                        <CardHeader style={{ minHeight: 70, paddingBottom: 5 }} avatar={<img src={imgImprovement} alt={'Ongoing Optimization'} style={{ width: 50, height: 50 }} />} title={<Typography style={{ textAlign: 'left', fontSize: 22, fontWeight: 550, color: '#3B83BD', fontFamily: 'Montserrat' }}>Ongoing Optimization</Typography>} />
                        <CardContent style={{ minHeight: 100, paddingTop: 5 }}>
                            <Typography style={{ fontSize: 14, paddingTop: 10 }}> Continuous feedback, data reviews, and usage audits ensure you’re optimizing the software’s potential.</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid size={{ xs: 3, sm: 3, md: 3 }}>
                    <Card variant='outlined' style={{ borderRadius: 10, textAlign: 'left', backgroundColor: '#F1F3F5', border: 'none' }} sx={{ ':hover': { boxShadow: 20, }, }}>
                        <CardHeader style={{ minHeight: 70, paddingBottom: 5 }} avatar={<img src={imgPartership} alt={'Long-Term Partnership'} style={{ width: 50 , height: 50}} />} title={<Typography style={{ textAlign: 'left', fontSize: 22, fontWeight: 550, color: '#3B83BD', fontFamily: 'Montserrat' }}>Long-Term Partnership</Typography>} />
                        <CardContent style={{ minHeight: 100, paddingTop: 5 }}>
                            <Typography style={{ fontSize: 14, paddingTop: 10 }}>As your business evolves, we help you adapt the solution to meet new challenges, unlocking sustained value and growth.</Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            <Typography style={{ fontSize: 30, fontWeight: 600, color: '#3B83BD', paddingTop: 40, paddingBottom: 10, textAlign: 'center', fontFamily: 'Montserrat' }}>Our 3-Step Approach to Ensuring Your Success</Typography>
            <Typography style={{ fontSize: 17, paddingTop: 10, paddingBottom: 20, textAlign: 'center', paddingLeft: '10%', paddingRight: '10%', fontFamily: 'Montserrat' }}>At Aaroha, we believe that true success begins after the software is implemented. Our partnership goes beyond deployment, ensuring your business continuously benefits from the solution, maximizing your investment through a long-term, hands-on approach.</Typography>
            <Grid container style={{ justifyContent: 'center', paddingLeft: '5%', paddingRight: '5%' }} spacing={{ xs: 1, md: 1 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                <Grid size={{ xs: 4, sm: 4, md: 4 }}>
                    <Card variant='outlined' style={{ backgroundColor: '#FDF7E6', border: 'none', borderRadius: 10, position: 'relative' }} sx={{ ':hover': { boxShadow: 20, }, }}>
                        <CardHeader style={{ padding: 10 }} avatar={<><img src={imgTrendup} alt={'Monitoring & Quick Wins'} style={{ width: 50, height: 50 }} /></>} />
                        <CardHeader style={{ padding: 10, minHeight: 90 }} title={<Typography style={{ fontSize: 20, fontWeight: 550, color: '#3B83BD' }}>Step 1: Early Monitoring & Quick Wins</Typography>} subheader={<Typography style={{ color: 'gray', textAlign: 'left', fontSize: 18 }}>(Month 1–2)</Typography>} />
                        <CardContent style={{ padding: 8, minHeight: 380 }}>
                            <Typography sx={{ fontSize: 16, color: 'text.secondary', paddingBottom: '10px' }}>We focus on delivering immediate value through early monitoring and quick process improvements.</Typography>
                            <Typography sx={{ fontSize: 16, color: 'text.secondary', paddingBottom: '10px' }}><strong>Process Validation:</strong>We track how the solution impacts your critical processes, ensuring early adoption translates to productivity and efficiency gains.</Typography>
                            <Typography sx={{ fontSize: 16, color: 'text.secondary' }}><strong>Quick Wins:</strong>We identify and leverage early successes that boost engagement and momentum, ensuring effective use from day one.</Typography><br />
                            <Typography sx={{ fontSize: 16, color: 'text.secondary' }}><strong>Real-Time Adjustments:</strong>Based on data and feedback, we make immediate refinements to optimize usage and address challenges as they arise.</Typography><br />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid size={{ xs: 4, sm: 4, md: 4 }}>
                    <Card variant='outlined' style={{ backgroundColor: '#FCEEEF', border: 'none', borderRadius: 10, position: 'relative' }} sx={{ ':hover': { boxShadow: 20, }, }}>
                        <CardHeader style={{ padding: 10 }} avatar={<><img src={imgOptimization} alt={'Optimization & Expansion'} style={{ width: 50, height: 50 }} /></>} />
                        <CardHeader style={{ padding: 10, minHeight: 90 }} title={<Typography style={{ fontSize: 20, fontWeight: 550, color: '#3B83BD' }}>Step 2: Optimization & Expansion</Typography>} subheader={<Typography style={{ color: 'gray', textAlign: 'left', fontSize: 18 }}>(Month 3–6)</Typography>} />
                        <CardContent style={{ padding: 8, minHeight: 380 }}>
                            <Typography sx={{ fontSize: 16, color: 'text.secondary', paddingBottom: '10px' }}>After the initial phase, we focus on scaling the solution’s impact across teams, ensuring consistent improvements throughout your organization.</Typography>
                            <Typography sx={{ fontSize: 16, color: 'text.secondary', paddingBottom: '10px' }}><strong>Process Optimization:</strong>We use data-driven insights to uncover inefficiencies and further refine processes for maximum efficiency.</Typography>
                            <Typography sx={{ fontSize: 16, color: 'text.secondary' }}><strong>Scaling Across Departments:</strong>Extend the solution’s benefits across teams, standardizing improvements and ensuring organization-wide alignment.</Typography><br />
                            <Typography sx={{ fontSize: 16, color: 'text.secondary' }}><strong>Advanced Insights:</strong>Leverage advanced analytics to deepen your understanding of how the solution drives value, continuously refining and optimizing it.</Typography><br />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid size={{ xs: 4, sm: 4, md: 4 }}>
                    <Card variant='outlined' style={{ backgroundColor: '#F1F3F5', border: 'none', borderRadius: 10, position: 'relative' }} sx={{ ':hover': { boxShadow: 20, }, }}>
                        <CardHeader style={{ padding: 10 }} avatar={<><img src={imgImprovement} alt={'Continuous Improvement & Long-Term Partnership'} style={{ width: 50 , height: 50}} /></>} />
                        <CardHeader style={{ padding: 10, minHeight: 90 }} title={<Typography style={{ fontSize: 20, fontWeight: 550, color: '#3B83BD' }}>Step 3: Continuous Improvement & Long-Term Partnership</Typography>} subheader={<Typography style={{ color: 'gray', textAlign: 'left', fontSize: 18 }}>(Month 6 and Beyond)</Typography>} />
                        <CardContent style={{ padding: 8, minHeight: 380 }}>
                            <Typography sx={{ fontSize: 16, color: 'text.secondary', paddingBottom: '10px' }}>We’re committed to being your long-term partner, helping you evolve and optimize your processes over time. As your business grows, we adapt the solution to meet your changing needs.</Typography>
                            <Typography sx={{ fontSize: 16, color: 'text.secondary', paddingBottom: '10px' }}><strong>Ongoing Process Audits:</strong>Regular audits ensure the software remains aligned with your business goals, identifying areas for continuous improvement.</Typography>
                            <Typography sx={{ fontSize: 16, color: 'text.secondary' }}><strong>Adaptation & Innovation:</strong>As new challenges arise; we help you innovate and refine processes to ensure sustained success and long-term value.</Typography><br />
                            <Typography sx={{ fontSize: 16, color: 'text.secondary' }}><strong>Long-Term Support:</strong>With continuous training, technical support, and updates, we ensure your teams stay ahead of the curve, maximizing the solution’s potential over time.</Typography><br />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            <h1 style={{ marginBlockEnd: 0, marginBlockStart: 0, fontSize: 30, fontWeight: 700, color: '#3B83BD', paddingTop: 40, paddingBottom: 10, textAlign: 'center', fontFamily: 'Montserrat' }}>Why Partner with Aaroha?</h1>
            <h3 style={{ marginBlockEnd: 0, marginBlockStart: 0, fontSize: 17, paddingTop: 10, paddingBottom: 20, textAlign: 'center', paddingLeft: '10%', paddingRight: '10%', fontFamily: 'Montserrat', fontWeight: 400 }}>At Aaroha, our commitment goes far beyond the initial implementation. We focus on helping you unlock the full potential of your software through ongoing support, process optimization, and long-term partnership. Our approach ensures your teams continue to improve productivity, efficiency, and performance long after deployment.</h3>

            <h1 style={{ marginBlockEnd: 0, marginBlockStart: 0, fontSize: 30, fontWeight: 700, color: '#3B83BD', paddingTop: 40, paddingBottom: 10, textAlign: 'center', fontFamily: 'Montserrat' }}>Ready to maximize your investment and drive long-term success?</h1>
            <h5 style={{ marginBlockEnd: 0, marginBlockStart: 0, fontSize: 17, paddingTop: 10, paddingBottom: 20, textAlign: 'center', paddingLeft: '10%', paddingRight: '10%', fontFamily: 'Montserrat', fontWeight: 400 }}><Link to="/Contactus" style={{ textDecoration: 'none' }}><strong>Contact us</strong></Link> today to learn more about how Aaroha can help your business achieve sustained operational excellence.</h5>

        </>);

}
