import React, { useState, useEffect } from 'react';
import { Close, Dashboard, Settings, Place, Assignment, Info } from '@mui/icons-material';
import { IconButton, Divider, Dialog, Typography, Button, Card, CardContent, CardHeader, Avatar } from '@mui/material';
import Grid from '@mui/material/Grid2';
import imgUnbaised from '../Images/unbaised.png'
import imgStreamlined from '../Images/Streamlined.png'
import imgAccountability from '../Images/Accountability.png'
import imgInsights from '../Images/Insights.png'
import imgRapid from '../Images/Rapid.png'
import '../style.css'
import { UserForm } from './UserForm';
import { Helmet } from 'react-helmet';

export function Precisely() {
    document.title = "Precisely: A Quality Assessment Tool with intelligent automation and real-time insights";

    const [openForm, setOpenForm] = React.useState(false);
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [])

    return (
        <>
            <Helmet>
                <title>Precisely - Elevate Quality Assurance with Intelligent Automation</title>
                <meta name="description" content="Elevate your quality assurance with Precisely. Revolutionize quality assessments using intelligent automation and real-time insights to ensure operational excellence and consistent product quality." />
                <meta name="keywords" content="Quality Assurance Software, Intelligent Automation, Operational Quality Management, Real-Time Quality Insights, Quality Assessments, Automated Quality Control, Process Optimization, Quality Management Tools, Product Quality Improvement, Operational Excellence" />
                <link rel="canonical" href="https://aarohasolutions.com/Precisely"></link>
                <meta property="og:url" content="https://aarohasolutions.com/Precisely" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Precisely - Elevate Quality Assurance with Intelligent Automation" />
                <meta property="og:site_name" content="Aaroha Solutions Precisely" />
                <meta property="og:description" content="Precisely revolutionizes quality assurance by providing intelligent automation and real-time insights, ensuring consistent product quality and operational excellence." />
                <meta property="og:image" content="https://aarohasolutions.com/static/media/precisely-logo.6433d1c5d899913706b1.png" />
                <meta property="og:image:width" content="2400" />
                <meta property="og:image:height" content="720" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Precisely - Elevate Quality Assurance with Intelligent Automation" />
                <meta name="twitter:description" content="Revolutionize your quality assessments with Precisely. Leverage intelligent automation and real-time insights for operational excellence and consistent product quality." />
                <meta name="twitter:image" content="https://aarohasolutions.com/static/media/precisely-logo.6433d1c5d899913706b1.png" />
            </Helmet>

            <div className='preciselywithoutbackgroudimage' style={{ textAlign: 'center', backgroundColor: '#f7f8fd', paddingTop: 20, paddingBottom: 50, paddingLeft: 20 }}>
                <h1 style={{ marginBlockEnd: 0, marginBlockStart: 0, fontSize: 55, fontWeight: 700, color: '#3B83BD', paddingTop: 100, textAlign: 'left' }}>Elevate Your Quality Assurance</h1>
                <h2 style={{ marginBlockEnd: 0, marginBlockStart: 0, fontSize: 25, fontWeight: 500, color: '#3B83BD', paddingTop: 20, textAlign: 'left' }}>Revolutionize your quality assessments with intelligent automation and real-time insights.</h2>
                <a href="#ready"><Button variant='contained' disableElevation style={{ textTransform: 'none', fontSize: 25, fontWeight: 500, marginTop: 20 }}>Get Started</Button></a>
            </div>

            <h1 style={{ marginBlockEnd: 0, marginBlockStart: 0, fontSize: 35, fontWeight: 700, color: '#3B83BD', paddingTop: 40, paddingBottom: 40, textAlign: 'center' }}>Why Precisely?</h1>
            <Grid container style={{ justifyContent: 'center', textAlign: 'center', paddingLeft: 2, paddingRight: 2, paddingBottom: 55 }} spacing={{ xs: 1, md: 1 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                <Grid size={{ xs: 3.8, sm: 3, md: 3 }}>
                    <Card variant='outlined' style={{ backgroundColor: '#f3f9ff', border: 'none', borderRadius: 10, textAlign: 'left' }} sx={{ ':hover': { boxShadow: 20, }, }}>
                        <CardContent style={{ padding: 10 }}><img src={imgUnbaised} alt={'Unbiased Audits'} style={{ width: 50 }} /></CardContent>
                        <CardContent style={{ minHeight: 150 }}>
                            <Typography style={{ textAlign: 'left', fontSize: 25, fontWeight: 600, color: '#3B83BD', }}>Unbiased Audits</Typography>
                            <h3 style={{ marginBlockEnd: 0, marginBlockStart: 0, fontWeight: 400, fontSize: 17 }}>Precisely’s random sampling algorithm increases audit accuracy by 25%.</h3>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid size={{ xs: 3.8, sm: 4, md: 4 }}>
                    <Card variant='outlined' style={{ backgroundColor: '#fceeef', border: 'none', borderRadius: 10, textAlign: 'left', marginLeft: 3 }} sx={{ ':hover': { boxShadow: 20, }, }}>
                        <CardContent style={{ padding: 10 }}><img src={imgStreamlined} alt={'Streamlined Management'} style={{ width: 50 }} /></CardContent>
                        <CardContent style={{ minHeight: 150 }}>
                            <Typography style={{ textAlign: 'left', fontSize: 25, fontWeight: 600, color: '#3B83BD', }}>Streamlined Management</Typography>
                            <h3 style={{ marginBlockEnd: 0, marginBlockStart: 0, fontWeight: 400, fontSize: 17 }}>Simplify audit management across locations, reducing audit time by 30%.</h3>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid size={{ xs: 3.8, sm: 4, md: 4 }}>
                    <Card variant='outlined' style={{ backgroundColor: '#fef6ec', border: 'none', borderRadius: 10, textAlign: 'left', marginLeft: 3 }} sx={{ ':hover': { boxShadow: 20, }, }}>
                        <CardContent style={{ padding: 10 }}><img src={imgAccountability} alt={'Enhanced Accountability'} style={{ width: 50 }} /></CardContent>
                        <CardContent style={{ minHeight: 150 }}>
                            <Typography style={{ textAlign: 'left', fontSize: 25, fontWeight: 600, color: '#3B83BD', }}>Enhanced Accountability</Typography>
                            <h3 style={{ marginBlockEnd: 0, marginBlockStart: 0, fontWeight: 400, fontSize: 17 }}>Check the Checker feature reduces audit errors by 20% through multi-level auditing.</h3>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid size={{ xs: 3.8, sm: 4, md: 4 }}>
                    <Card variant='outlined' style={{ backgroundColor: '#ecf8f2', border: 'none', borderRadius: 10, textAlign: 'left', marginLeft: 3 }} sx={{ ':hover': { boxShadow: 20, }, }}>
                        <CardContent style={{ padding: 10 }}><img src={imgInsights} alt={'Real-Time Insights'} style={{ width: 50 }} /></CardContent>
                        <CardContent style={{ minHeight: 150 }}>
                            <Typography style={{ textAlign: 'left', fontSize: 25, fontWeight: 600, color: '#3B83BD' }}>Real-Time Insights</Typography>
                            <h3 style={{ marginBlockEnd: 0, marginBlockStart: 0, fontWeight: 400, fontSize: 17 }}>Get real-time dashboards and reports to improve service quality by 15%.</h3>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid size={{ xs: 3.8, sm: 4, md: 4 }}>
                    <Card variant='outlined' style={{ backgroundColor: '#f3f9ff', border: 'none', borderRadius: 10, textAlign: 'left', marginLeft: 3 }} sx={{ ':hover': { boxShadow: 20, }, }}>
                        <CardContent style={{ padding: 10 }}><img src={imgRapid} alt={'Rapid Deployment'} style={{ width: 50 }} /></CardContent>
                        <CardContent style={{ minHeight: 150 }}>
                            <Typography style={{ textAlign: 'left', fontSize: 25, fontWeight: 600, color: '#3B83BD' }}>Rapid Deployment</Typography>
                            <h3 style={{ marginBlockEnd: 0, marginBlockStart: 0, fontWeight: 400, fontSize: 17 }}>Start benefiting from Precisely in just one week with 35% faster setup</h3>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            <div style={{ backgroundColor: '#f7f8fd', paddingTop: 10, paddingBottom: 50 }} id="solutions">
                <h1 style={{ marginBlockEnd: 0, marginBlockStart: 0, fontSize: 30, fontWeight: 700, color: '#3B83BD', paddingTop: 50, paddingBottom: 50, textAlign: 'center' }}>Precisely Capabilities: The Backbone of Quality Excellence</h1>
                <Grid container style={{ justifyContent: 'center', paddingLeft: 2 }} spacing={{ xs: 1, md: 1 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                    <Grid size={{ xs: 3.8, sm: 4, md: 4 }}>
                        <Card variant='outlined' style={{ borderColor: '#f7f8fd', borderRadius: 10, position: 'relative', minHeight: 230 }} sx={{ ':hover': { boxShadow: 20, }, }}>
                            <CardHeader avatar={<Avatar sx={{ backgroundColor: '#3B83BD', width: 45, height: 45 }}><Assignment sx={{ color: 'white', fontSize: 30 }} /></Avatar>} title={<Typography style={{ fontSize: 25, fontWeight: 600 }}>Post-Issue Audits</Typography>} />
                            <CardContent>
                                <h3 style={{ marginBlockEnd: 0, marginBlockStart: 0, fontWeight: 400, fontSize: 18, color: 'gray' }}>Random sampling algorithm for unbiased case selection and multi-level auditing for thorough quality checks.</h3>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid size={{ xs: 3.8, sm: 4, md: 4 }}>
                        <Card variant='outlined' style={{ borderColor: '#f7f8fd', borderRadius: 10, position: 'relative', minHeight: 230 }} sx={{ ':hover': { boxShadow: 20, }, }}>
                            <CardHeader avatar={<Avatar sx={{ backgroundColor: '#3B83BD', width: 45, height: 45 }}><Dashboard sx={{ color: 'white', fontSize: 30 }} /></Avatar>} title={<Typography style={{ fontSize: 25, fontWeight: 600 }}>Dashboards & Reports</Typography>} />
                            <CardContent>
                                <h3 style={{ marginBlockEnd: 0, marginBlockStart: 0, fontWeight: 400, fontSize: 18, color: 'gray' }}>Real-time performance monitoring with comprehensive reporting on audit statistics and quality trends.</h3>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid size={{ xs: 3.8, sm: 4, md: 4 }}>
                        <Card variant='outlined' style={{ borderColor: '#f7f8fd', borderRadius: 10, position: 'relative', minHeight: 230 }} sx={{ ':hover': { boxShadow: 20, }, }}>
                            <CardHeader avatar={<Avatar sx={{ backgroundColor: '#3B83BD', width: 45, height: 45 }}><Settings sx={{ color: 'white', fontSize: 30 }} /></Avatar>} title={<Typography style={{ fontSize: 25, fontWeight: 600 }}>System Configuration</Typography>} />
                            <CardContent>
                                <h3 style={{ marginBlockEnd: 0, marginBlockStart: 0, fontWeight: 400, fontSize: 18, color: 'gray' }}>Customizable checklists, user roles, and flexible management to adapt to your business needs.</h3>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid size={{ xs: 3.8, sm: 4, md: 4 }}>
                        <Card variant='outlined' style={{ borderColor: '#f7f8fd', borderRadius: 10, position: 'relative', minHeight: 230 }} sx={{ ':hover': { boxShadow: 20, }, }}>
                            <CardHeader avatar={<Avatar sx={{ backgroundColor: '#3B83BD', width: 45, height: 45 }}><Place sx={{ color: 'white', fontSize: 30 }} /></Avatar>} title={<Typography style={{ fontSize: 25, fontWeight: 600 }}>Multi-Location Support</Typography>} />
                            <CardContent>
                                <h3 style={{ marginBlockEnd: 0, marginBlockStart: 0, fontWeight: 400, fontSize: 18, color: 'gray' }}>Seamlessly integrate Precisely with existing systems for consistent quality management across locations.</h3>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid size={{ xs: 3.8, sm: 4, md: 4 }}>
                        <Card variant='outlined' style={{ borderColor: '#f7f8fd', borderRadius: 10, position: 'relative', minHeight: 230 }} sx={{ ':hover': { boxShadow: 20, }, }}>
                            <CardHeader avatar={<Avatar sx={{ backgroundColor: '#3B83BD', width: 45, height: 45 }}><Info sx={{ color: 'white', fontSize: 30 }} /></Avatar>} title={<Typography style={{ fontSize: 25, fontWeight: 600 }}>Dispute Resolution</Typography>} />
                            <CardContent>
                                <h3 style={{ marginBlockEnd: 0, marginBlockStart: 0, fontWeight: 400, fontSize: 18, color: 'gray' }}>Empower employees with the ability to review scores, compare with peers, and raise disputes.</h3>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </div>
            <h1 style={{ marginBlockEnd: 0, marginBlockStart: 0, color: '#3B83BD', fontSize: 35, fontWeight: 700, paddingTop: 50, textAlign: 'center', backgroundColor: '#f7f8fd' }}>Ready to Revolutionize Your Quality Management?</h1>
            <div id="ready" style={{ justifyContent: 'center', textAlign: 'center', backgroundColor: '#f7f8fd' }}>
                <Button variant='contained' disableElevation style={{ textTransform: 'none', fontSize: 25, fontWeight: 500, marginTop: 20, marginBottom: 30, textAlign: 'center', justifySelf: 'center' }} onClick={(x) => setOpenForm(true)}>Get Started Today</Button>
            </div>

            <Dialog open={openForm} fullWidth={true} style={{ padding: 0, }} maxWidth={'md'} disableEscapeKeyDown>
                <Card>
                    <CardHeader style={{ padding: 6, paddingLeft: 10, paddingRight: 10, backgroundColor: '#e6e6e6' }} title={'Get document'} titleTypographyProps={{ variant: 'body1' }} action={<IconButton aria-label="Close" onClick={() => setOpenForm(false)}><Close color='error' /></IconButton>} />
                    <Divider />
                    <div style={{ backgroundColor: '#F7F8FD', textAlign: 'center', height: 50 }}>
                        <Typography style={{ color: '#2e2e2e', fontSize: 20, fontWeight: 500, paddingTop: 10 }}>Get the <strong>Precisely</strong> document on your email id.</Typography>
                    </div>
                    <UserForm page='Precisely' action='Send Email' />
                </Card>
            </Dialog>
        </>
    );
}