import './App.css';
import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Layout } from './Layout';
import { ToastContainer } from 'react-toastify';

import { Home } from './Components/Home'
import { Momentum } from './Components/Momentum';
import { Flow } from './Components/Flow';
import { Precisely } from './Components/Precisely';
import { Clarity } from './Components/Clarity';
import { AboutUs } from './Components/AboutUs';

//import { Industries } from './Components/Industries';
//import { ContactUs } from './Components/ContactUs';

const Industries = React.lazy(() => import('./Components/Industries'));
const ContactUs = React.lazy(() => import('./Components/ContactUs'));

function App() {
  return (
    <>  
        <Layout>
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route exact path='/' element={<Home />} />
              <Route exact path='/Home' element={<Home />} />
              <Route exact path='/Industries' element={<Industries />} />
              <Route exact path='/ContactUs' element={<ContactUs />} />
              <Route exact path='/Momentum' element={<Momentum />} />
              <Route exact path='/Flow' element={<Flow />} />
              <Route exact path='/Precisely' element={<Precisely />} />
              <Route exact path='/Clarity' element={<Clarity />} />
              <Route exact path='/AboutUs' element={<AboutUs />} />
            </Routes>
          </Suspense>
        </Layout> 
      <ToastContainer />
    </>
  );
}

export default App;
